import { apiUtils } from '../api-utils';
import { PermissionCategory } from '../enums/permissionCategoryEnum';
import { PermissionLevel } from '../enums/permissionLevelEnum';
import { UserType } from '../enums/userTypeEnum';


export * as notifications from './notifications/notifications-api';

const { INTERNAL, CLIENT, CUSTOMER } = UserType;

export function getRoles(userType: UserType, customerID?: number): Promise<Role[]> {
    return apiUtils.get(getRoleURL(userType, customerID));
}

export function deleteRole(userType: UserType, roleID: number, customerID?: number): Promise<void> {
    return apiUtils.delete(getRoleURL(userType, customerID, roleID));
}

export function addRole(userType: UserType, role: Omit<Role, 'id'>, customerID?: number): Promise<Role> {
    return apiUtils.post(getRoleURL(userType, customerID), role);
}

export function editRole(userType: UserType, role: Role, customerID?: number): Promise<Role> {
    return apiUtils.put(getRoleURL(userType, customerID, role.id), role);
}

export function getRole(userType: UserType, roleID: number, customerID?: number): Promise<Role> {
    return apiUtils.get(getRoleURL(userType, customerID, roleID));
}

export function getPermissions(userType: UserType, roleID: number, customerID?: number): Promise<Permission[]> {
    return apiUtils.get(`${getRoleURL(userType, customerID, roleID)}/permissions`);
}

export function editPermissions(
    userType: UserType, roleID: number, permissions: Permission[], customerID?: number
): Promise<Permission[]> {
    return apiUtils.put(`${getRoleURL(userType, customerID, roleID)}/permissions`, permissions);
}

/**
 * TODO client / customer endpoints are not finalized
 */
export function getRoleURL(userType: UserType, customerID: number | undefined, roleID?: number) {
    const urls = roleID ? ({
        [INTERNAL]: `/auth/role/${roleID}`,
        [CLIENT]: `/client/role/${roleID}`,
        [CUSTOMER]: `/customer/${customerID}/role/${roleID}`
    }) : ({
        [INTERNAL]: '/auth/role',
        [CLIENT]: '/client/role',
        [CUSTOMER]: `/customer/${customerID}/role`
    });
    return urls[userType];
}

export interface Role {
    id: number;
    userType: UserType;
    name: string;
    description: string;
    isCustom: boolean;
}

export interface Permission {
    id: number;
    category: PermissionCategory;
    description: string;
    allowAssigned: boolean;
    level: PermissionLevel;
}
