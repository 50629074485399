import api, { CommonLoanProgram } from '@api';
import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useGetLoanProgram } from '@utils/hooks';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { LoanProgramDetailsFields } from '../components/LoanProgramDetailsFields';

import styles from './EditLoanProgramDetailsDialog.module.scss';


export function EditLoanProgramDetailsDialog() {
    const [ loanProgram, setLoanProgram ] = useGetLoanProgram();
    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(false);

    const formMethods = useForm<CommonLoanProgram>({ defaultValues: loanProgram });

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setLoading(true);

        try {
            setLoanProgram(await api.investors.updateLoanProgram(formData));

            navigate('..');

            pageMessage.success('Loan program updated');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the loan program', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            closeTo=".."
            title="Edit loan program details"
            saveLoading={loading}
        >
            <DialogContent>
                <form
                    id="loan-program-form"
                    onSubmit={handleSubmit}
                    className={styles.root}
                >
                    <FormProvider {...formMethods}>
                        <LoanProgramDetailsFields />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    component={Link}
                    to=".."
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    form="loan-program-form"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
