import api, {
    LoanDetail, LoanEvent, LoanStatus, loanStatusDisplay
} from '@api';
import { Description, OpenInNew, PendingActions } from '@mui/icons-material';
import {
    Avatar, Button, ButtonBase, Divider, IconButton, Popover, Tooltip
} from '@mui/material';
import { Loader } from '@tsp-ui/core/components';
import {
    getFullName, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import ContactDisplay from '@views/admin/customers/CustomerDetailPage/components/dialogs/ContactDisplay';
import LoanDataDialog from '@views/loans/components/LoanDataDialog';
import LoanDocumentsDialog from '@views/loans/components/LoanDocumentsDialog';
import ConditionsSummary from '@views/loans/underwriting/components/ConditionsSummary';
import { useCallback, useState } from 'react';

import Page from '../components/Page';

import styles from './LoanDetailPage.module.scss';
import { LoanTimeline } from './components/LoanTimeline';
import AUSUnderwritingPage from './underwriting/AUSUnderwritingPage';
import AppraisalUnderwritingPage from './underwriting/AppraisalUnderwritingPage';
import AssetsUnderwritingPage from './underwriting/AssetsUnderwritingPage';
import CreditUnderwritingPage from './underwriting/CreditUnderwritingPage';
import FloodAndHazardUnderwritingPage from './underwriting/FloodAndHazardUnderwritingPage';
import FraudReportUnderwritingPage from './underwriting/FraudReportUnderwritingPage';
import SalesContractUnderwritingPage from './underwriting/SalesContractUnderwritingPage';
import TitleUnderwritingPage from './underwriting/TitleUnderwritingPage';
import UnderwritingDocumentDialog from './underwriting/components/UnderwritingDocumentDialog';
import UnderwritingSidebar from './underwriting/components/UnderwritingSidebar';


export enum UnderwritingTab {
    AUS = 'aus',
    CREDIT_LIABILITIES = 'credit-and-liabilities',
    INCOME_ASSETS = 'income-and-assets',
    APPRAISAL = 'appraisal',
    TITLE = 'title',
    SALES_CONTRACT = 'sales-contract',
    FLOOD_HAZARD = 'flood-hazard',
    FRAUD = 'fraud'
}

const {
    AUS, CREDIT_LIABILITIES, INCOME_ASSETS, APPRAISAL, TITLE, SALES_CONTRACT, FLOOD_HAZARD, FRAUD
} = UnderwritingTab;

export interface LoanDetailPageParams {
    loanID: string;
    underwritingTab: UnderwritingTab;
}

/**
 * Renders the loan detail page
 *
 * @constructor
 */
export default function LoanDetailPage() {
    const [ loanDetail, setLoanDetail ] = useState<LoanDetail>();
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);

    const { underwritingTab, loanID } = useParams<{ loanID: string }>();
    const pageMessage = usePageMessage();

    const [ dataDialogOpen, setDataDialogOpen ] = useState(false);
    const openLoanData = () => setDataDialogOpen(true);

    const [ docDialogOpen, setDocDialogOpen ] = useState(false);
    const openLoanDocs = () => setDocDialogOpen(true);

    const [ assigneeAnchor, setAssigneeAnchor ] = useState<HTMLElement>();

    useAsyncEffect(useCallback(async () => {
        try {
            setLoanDetail(await api.loans.getLoanDetail(loanID));
            setLoading(false);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan details', error);
        }
    }, [ loanID, pageMessage ]));

    return (
        <Page
            header={(loading ? null
                : (
                    <div className={styles.header}>
                        Loan #{loanDetail!.loanNumber}

                        <div>
                            <Tooltip title="View loan data">
                                <IconButton onClick={openLoanData}>
                                    <OpenInNew color="secondary" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="View loan documents">
                                <IconButton onClick={openLoanDocs}>
                                    <Description color="secondary" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                )
            )}
            headerActions={loading ? null : (
                <>
                    <LoanStatusButton status={loanDetail!.loanStatus} />

                    {loanDetail?.assignee ? (
                        <Tooltip
                            classes={{
                                tooltip: styles.assignedToTooltip
                            }}
                            title={(
                                <>
                                    Assigned to: {getFullName(loanDetail.assignee)}

                                    <br />
                                    Click for details
                                </>
                            )}
                        >
                            <Avatar
                                component={ButtonBase}
                                className={styles.avatar}
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAssigneeAnchor(
                                    event.currentTarget
                                )}
                            >
                                {`${loanDetail.assignee.firstName.charAt(0)}${loanDetail.assignee.lastName.charAt(0)}`}
                            </Avatar>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            classes={{
                                tooltip: styles.assignedToTooltip
                            }}
                            title="Unassigned"
                        >
                            <Avatar className={styles.avatar} />
                        </Tooltip>
                    )}
                </>
            )}
            loading={!loanDetail}
            breadcrumbs={[
                'Loans',
                loanDetail?.loanNumber && `#${loanDetail?.loanNumber}`
            ]}
        >
            {loanDetail && (
                <>
                    <LoanDataDialog
                        loanDetail={loanDetail}
                        open={dataDialogOpen}
                        onClose={() => setDataDialogOpen(false)}
                    />

                    <LoanDocumentsDialog
                        loanNumber={loanDetail.loanNumber}
                        loanStatus={loanDetail.loanStatus}
                        open={docDialogOpen}
                        onClose={() => setDocDialogOpen(false)}
                    />
                </>
            )}

            <Divider className={styles.divider} />

            <div className={styles.content}>
                <UnderwritingSidebar />

                {underwritingTab === AUS ? (
                    <AUSUnderwritingPage />
                ) : underwritingTab === CREDIT_LIABILITIES ? (
                    <CreditUnderwritingPage />
                ) : underwritingTab === INCOME_ASSETS ? (
                    <AssetsUnderwritingPage />
                ) : underwritingTab === APPRAISAL ? (
                    <AppraisalUnderwritingPage />
                ) : underwritingTab === TITLE ? (
                    <TitleUnderwritingPage />
                ) : underwritingTab === SALES_CONTRACT ? (
                    <SalesContractUnderwritingPage />
                ) : underwritingTab === FLOOD_HAZARD ? (
                    <FloodAndHazardUnderwritingPage />
                ) : underwritingTab === FRAUD ? (
                    <FraudReportUnderwritingPage />
                ) : (
                    <>
                        <div />

                        <ConditionsSummary
                            header="All conditions"
                            noConditions
                        />
                    </>
                )}
            </div>

            <UnderwritingDocumentDialog
                open={open}
                onClose={() => setOpen(false)}
            />

            <Popover
                open={!!assigneeAnchor}
                onClose={() => setAssigneeAnchor(undefined)}
                anchorEl={assigneeAnchor}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.popover
                }}
            >
                <ContactDisplay
                    contact={{
                        name: getFullName(loanDetail?.assignee),
                        phone: `${loanDetail?.assignee?.phone}`,
                        email: `${loanDetail?.assignee?.email}`
                    }}
                />
            </Popover>
        </Page>
    );
}

interface LoanStatusButtonProps {
    status: LoanStatus;
    loanID?: string;
}

export function LoanStatusButton({ status, loanID: loanIdProp }: LoanStatusButtonProps) {
    const [ loanEvents, setLoanEvents ] = useState<LoanEvent[]>();
    const [ eventsLoading, setEventsLoading ] = useState(false);
    const [ timelineAnchor, setTimelineAnchor ] = useState<HTMLElement>();

    const loanID = useParams<{ loanID: string }>().loanID || loanIdProp!;
    const pageMessage = usePageMessage();

    async function openTimeline(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setTimelineAnchor(event.currentTarget);
        if (!loanEvents) {
            setEventsLoading(true);
            try {
                setLoanEvents(await api.loans.getLoanEvents(loanID));
                setEventsLoading(false);
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching loan events', error);
            }
        }
    }

    return (
        <>
            <Tooltip title="View loan timeline">
                <Button
                    variant="outlined"
                    startIcon={<PendingActions />} // TODO post-demo vary this icon by status type
                    onClick={openTimeline}
                >
                    {loanStatusDisplay[status]}
                </Button>
            </Tooltip>

            <Popover
                open={!!timelineAnchor}
                onClose={() => setTimelineAnchor(undefined)}
                anchorEl={timelineAnchor}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.popover
                }}
            >
                {eventsLoading ? <Loader loading /> : ( // TODO post-demo make this loader look better
                    <LoanTimeline
                        isAwaitingDocs={status === LoanStatus.AWAITING_DOCS}
                        isInProgress={status === LoanStatus.IN_SETUP}
                        loanEvents={loanEvents || []}
                    />
                )}
            </Popover>
        </>
    );
}
