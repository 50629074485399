import { apiUtils } from '../api-utils';
import { UserType } from '../enums/userTypeEnum';


export function becomeClient(clientID: string): Promise<AuthResult> {
    return apiUtils.post('/auth/client', clientID);
}

export function fetchAccounts(): Promise<Account[]> {
    return apiUtils.get('/auth/client');
}

export interface Account {
    id: string;
    name: string;
    logoUrl: string;
    userType: UserType;
    // TODO post-demo change this to string, remove unneccesary toString on customerId return from useGetCurrentAccount
    customerId?: number;
}

export interface AuthResult {
    accessToken: string;
}
