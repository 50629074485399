import api, {
    LoanPricingResult, LoanPricingResultDetail, PendingUpload
} from '@api';
import {
    createDate, getRandomEnumValue, getRandomItemFromArray, getRandomNumberEnumValue, randomBoolean, randomNum
} from '@tsp-ui/core/utils';
import { rest } from 'msw';

import {
    AmortizationType, CommitmentType, LockPeriod, ProductType, amortizationTypeDisplay, productTypeDisplay
} from '..';
import { getMockUrl } from '../../mocks/getMockUrl';
import { SAMPLE_PDF_URL } from '../customer/checklist/checklist-mocks';


export const mocks = [
    rest.get(getMockUrl('/pricing'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json(loanPricingResults)
    ))),
    // TODO move to loans
    rest.get(getMockUrl('/pricing/floated'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json(floatedLoans)
    ))),
    rest.get(getMockUrl('/pricing/:pricingResultID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json(getLoanPricingResultDetail())
    ))),
    rest.post<{ loanID: string }>(getMockUrl('/pricing/reprice'), ({ body }, res, ctx) => res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json<LoanPricingResult>({
            ...(
                loanPricingResults.find(({ loanId }) => loanId === body.loanID)
                || floatedLoans.find(({ loanId }) => loanId === body.loanID)
            )!,
            pricedDate: createDate(-10).toISOString(),
            expirationDate: createDate(365).toISOString()
        })
    )),
    rest.post(getMockUrl('/pricing/price'), (req, res, ctx) => {
        api.webSocket.simulatePricingComplete();

        return (res(
            ctx.status(200),
            ctx.json(loanPricingResults[0])
        ));
    })
];

let loanID = 1;
let loanNumber = 7543670;

const loanPricingResults: LoanPricingResult[] = [
    getMockedPricingResult('Jim Burrows'),
    getMockedPricingResult('John Burrows'),
    getMockedPricingResult('Jeff Burrows', true)
].reverse();

const floatedLoans: LoanPricingResult[] = [ getMockedPricingResult('Jane Burrows') ];

export function getMockedPricingResult(borrowerName: string, isExpired?: boolean): LoanPricingResult {
    return {
        loanId: String(loanID++),
        loanNumber: String(loanNumber++),
        loanAmount: randomNum(100, 175) * 1000,
        interestRate: randomNum(4, 8, 2),
        borrowerName,
        rateSheetId: '1',
        pricedDate: '2021-12-12T05:00:00.000Z',
        expirationDate: createDate(isExpired ? -10 : 365).toISOString()
    };
}

const loanTerms = [
    '10', '15', '20', '30'
];
const GSEs = [ 'Fannie Mae', 'Freddie Mac' ];
const sampleAdjustmentNames = [
    'Loan amount is > $30,000 AND State is OK',
    'FICO is >= 740 AND LTV is between 80% - 85%',
    'Loan amt is $200,001 - Limit',
    'Cap adjustment'
];

export const generateSampleProductName = (productType?: ProductType, amorType?: AmortizationType) => [
    amortizationTypeDisplay[amorType || getRandomEnumValue(AmortizationType)],
    `${getRandomItemFromArray(loanTerms)}Yr`,
    getRandomItemFromArray(GSEs),
    productTypeDisplay[productType || getRandomEnumValue(ProductType)]
].join(' ');

let productID = 100;
const getLoanPricingResultDetail = (): LoanPricingResultDetail => ({
    defaultLockPeriod: 7,
    products: [ ...Array(randomNum(20, 30)) ].map(() => ({
        productId: `${productID++}`,
        basePrice: randomNum(90, 110, 2),
        commitmentType: randomBoolean() ? CommitmentType.MANDATORY : CommitmentType.BEST_EFFORT,
        description: generateSampleProductName(),
        docUrl: SAMPLE_PDF_URL,
        finalPrice: randomNum(90, 110, 2),
        investor: 'Agency',
        llpas: randomNum(-.5, .5, 3),
        lockPeriod: getRandomNumberEnumValue(LockPeriod),
        margin: randomNum(-.5, .5, 3),
        adjustments: Math.random() < 0.8 ? sampleAdjustmentNames.map((description, index) => ({
            id: index.toString(),
            description,
            amount: randomNum(-.5, .5, 3),
            srp: randomBoolean(),
            gos: randomBoolean(),
            isCap: randomBoolean(),
            capTotal: randomNum(-.5, .5, 3)
        })) : []
    })),
    ineligibleProducts: [
        {
            productId: '1',
            description: generateSampleProductName(),
            ineligibleReason: 'Federal housing loans not permitted above 20Yr term'
        },
        {
            productId: '1',
            description: generateSampleProductName(),
            ineligibleReason: 'Fixed required'
        }
    ]
});

export const pendingUploads: PendingUpload[] = [
    {
        fileId: '1',
        fileName: 'File one.fnm',
        status: 'pending'
    }, {
        fileId: '2',
        fileName: 'File two.fnm',
        status: 'pending'
    }, {
        fileId: '3',
        fileName: 'File three.fnm',
        status: 'pending'
    }, {
        fileId: '4',
        fileName: 'File four.fnm',
        status: 'pending'
    }, {
        fileId: '5',
        fileName: 'File five.fnm',
        status: 'pending'
    }
];
