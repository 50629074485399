import {
    AmortizationType,
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    CommitmentType,
    DocumentationType,
    Loan,
    LoanActivity,
    LoanAlert,
    LoanEvent,
    LoanEventType,
    LoanPurpose,
    LoanRegistrationResponse,
    LoanStatus,
    LoanType,
    NumUnits,
    OccupancyType,
    PipelineGroup,
    PropertyType,
    SpecialtyProgram,
    State,
    loanStatusDisplay
} from '@api';
import {
    createDate, getItemById, getRandomEnumValue, getRandomItemFromArray, randomBoolean, randomNum
} from '@tsp-ui/core/utils';
import { parseISO } from 'date-fns';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { pendingUploads } from '../pricing/pricing-mocks';
import { internalUsers } from '../user/user-mocks';

import { LoanDetail } from './loan-api';


let loanID = 0;
let clientLoanNumber = 7543670;
let customerLoanNumber = 100;
let pipelineGroupID = 300;
let loanAlertID = 500;
let loanActivityID = 600;
let loanTimelineEventID = 700;

export const mocks = [
    rest.post(getMockUrl('/loan/file-upload'), (req, res, ctx) => {
        let status;
        let result = {};

        if (typeof req.body === 'object') {
            status = 200;
            result = pendingUploads;
        } else {
            status = 500;
        }

        return (res(
            ctx.status(status),
            ctx.json(result)
        ));
    }),
    rest.get(getMockUrl('/loan'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(loans)
    ))),
    rest.get(getMockUrl('/loan/alert'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(loanAlerts)
    ))),
    rest.get(getMockUrl('/loan/recent'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(recentlyActiveLoans)
    ))),
    rest.get(getMockUrl('/loan/group'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(pipelineGroups)
    ))),
    rest.post(getMockUrl('/loan/register'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json(loanRegistrationResponse)
    ))),
    rest.get(getMockUrl('/loan/:loanID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(getLoanDetail(req.params.loanID as string))
    )),
    rest.get(getMockUrl('/loan/:loanID/events'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(getLoanEvents(req.params.loanID as string))
    ))
];

const firstNames = [
    'Jeff', 'Jim', 'John', 'Jane'
];
const lastNames = [
    'Burrows', 'Baker', 'Brown', 'Bailey'
];

const pipelineGroups: PipelineGroup[] = Object.keys(loanStatusDisplay).filter(
    loanStatus => loanStatus !== LoanStatus.IN_SETUP
).map(loanStatus => ({
    id: String(pipelineGroupID++),
    title: getPipelineGroupTitle(loanStatus as LoanStatus)
}));

function getPipelineGroupTitle(loanStatus: LoanStatus) {
    return (loanStatus === LoanStatus.AWAITING_DOCS || loanStatus === LoanStatus.IN_SETUP) ? 'Awaiting Docs & Setup' : loanStatusDisplay[loanStatus];
}

function getLoans(): Loan[] {
    return [ ...Array(randomNum(10, 18)) ].map(() => {
        const loanStatus = getRandomEnumValue(LoanStatus);

        return {
            id: String(loanID++),
            loanNumber: String(clientLoanNumber++),
            customerLoanNumber: String(customerLoanNumber++),
            borrowerName: `${getRandomItemFromArray(firstNames)} ${getRandomItemFromArray(lastNames)}`,
            loanAmount: randomNum(20, 500) * 1000,
            loanStatus,
            expirationDate: createDate(randomNum(-15, 180)).toISOString(),
            interestRate: randomNum(4, 8, 3),
            pipelineGroupID: pipelineGroups.find(
                pipelineGroup => pipelineGroup.title === getPipelineGroupTitle(loanStatus)
            )!.id
        };
    });
}

export const loans = getLoans();

const loanAlerts: LoanAlert[] = [ ...Array(randomNum(2, 4)) ].map((value, index) => (
    {
        id: String(loanAlertID++),
        loanID: loans[index].id,
        loanNumber: loans[index].loanNumber,
        alertType: randomBoolean() ? 'One condition left' : 'No activity for 3 days',
        triggeredAt: createDate(randomNum(-2, -1 / 24 / 60, 10)).toISOString() // between 2 days and 1 minute ago
    }
)).sort((a, b) => parseISO(b.triggeredAt).getTime() - parseISO(a.triggeredAt).getTime());

const recentlyActiveLoans: LoanActivity[] = [ ...Array(3) ].map((value, index) => ({
    id: String(loanActivityID++),
    loanID: loans[index].id,
    loanNumber: loans[index].loanNumber,
    lastActiveAt: createDate(randomNum(-2, -1 / 24 / 60, 10)).toISOString() // between 2 days and 1 minute ago
})).sort((a, b) => parseISO(b.lastActiveAt).getTime() - parseISO(a.lastActiveAt).getTime());

const loanRegistrationResponse: LoanRegistrationResponse = {
    clientLoanNumber: String(clientLoanNumber++),
    premicorrLoanID: String(loanID++)
};

const loanEventsBase: Omit<LoanEvent, 'loanId'>[] = [
    {
        id: String(loanTimelineEventID++),
        eventType: LoanEventType.CREATED,
        triggeredBy: internalUsers[0],
        triggeredAt: createDate(-.25).toISOString()
    },
    {
        id: String(loanTimelineEventID++),
        eventType: LoanEventType.PRICED,
        triggeredBy: internalUsers[0],
        triggeredAt: createDate(-.2).toISOString()
    },
    {
        id: String(loanTimelineEventID++),
        eventType: LoanEventType.REPRICED,
        triggeredBy: internalUsers[0],
        triggeredAt: createDate(-.1).toISOString()
    },
    {
        id: String(loanTimelineEventID++),
        eventType: LoanEventType.LOCKED,
        triggeredBy: internalUsers[0],
        triggeredAt: createDate(-.05).toISOString()
    },
    {
        id: String(loanTimelineEventID++),
        eventType: LoanEventType.INITIAL_DOC_PACKAGE_UPLOADED,
        triggeredBy: internalUsers[0],
        triggeredAt: createDate(-.025).toISOString()
    }
];

function getLoanEvents(loanID: string): LoanEvent[] {
    return (getItemById(loans, loanID).loanStatus === LoanStatus.AWAITING_DOCS
        ? loanEventsBase.slice(0, -1) // if awaiting docs, don't include the INITIAL_DOC_PACKAGE_UPLOADED event
        : loanEventsBase
    ).map(event => ({
        ...event,
        loanId: loanID
    }));
}

function getLoanDetail(loanID: string): LoanDetail {
    const loan = loans.find(loan => loan.id === loanID)!;
    const amortizationType = getRandomEnumValue(AmortizationType);

    return {
        id: loanID,
        assignee: internalUsers[0],
        loanNumber: loan.loanNumber,
        loanStatus: loan.loanStatus,
        loanType: getRandomEnumValue(LoanType),
        loanAmount: loan.loanAmount,
        interestRate: loan.interestRate,
        amortizationType,
        armMargin: .01,
        armInitialCap: .02,
        armSubsequentCap: .02,
        armLifeCap: .02,
        escrowsFlag: randomBoolean(),
        interestOnlyFlag: randomBoolean(),
        loanTerm: 360,
        lockPeriod: 30,
        borrowers: [
            {
                firstName: 'First',
                middleName: 'Middle',
                lastName: 'Last',
                fico: randomNum(500, 800),
                ssn: '123-44-2364',
                email: 'name@company.com',
                primaryWageEarner: randomBoolean(),
                firstTimeHomeBuyer: randomBoolean()
            },
            {
                firstName: 'Some',
                middleName: 'Other',
                lastName: 'Person',
                fico: randomNum(500, 800),
                ssn: '485-73-1284',
                email: 'name@company.com',
                primaryWageEarner: randomBoolean(),
                firstTimeHomeBuyer: randomBoolean()
            }
        ],
        propertyType: getRandomEnumValue(PropertyType),
        units: getRandomEnumValue(NumUnits),
        occupancy: getRandomEnumValue(OccupancyType),
        purpose: getRandomEnumValue(LoanPurpose),
        address: {
            street: '1234 Some Ln',
            city: 'Somewhere',
            state: State.SC,
            zip: '29414'
        },
        appraisedValue: randomNum(25, 30) * 1000,
        salePrice: randomNum(20, 25) * 1000,

        customerId: '1',
        customerName: 'Jeff Bowen',
        customerLoanNumber: '44759925',
        productCode: 'Some code',
        specialtyProgram: getRandomEnumValue(SpecialtyProgram),
        documentationType: getRandomEnumValue(DocumentationType),
        subordinatedBalance: randomNum(5, 10) * 1000,
        cashOutAmount: randomNum(5, 10) * 1000,
        limitedLiabilityCorp: 'Some corp',
        commitmentType: getRandomEnumValue(CommitmentType),
        commitmentIdentifier: 'SCO',
        comments: 'Adding a note',
        mortgageInsFlag: randomBoolean(),
        mortgageInsCompany: 'Ins co',
        mortgageInsCoverage: randomNum(10, 200) * 1000,
        underwriteFlag: randomBoolean(),
        automatedUwSystem: getRandomEnumValue(AutomatedUwSystem),
        automatedUwRecommendation: getRandomEnumValue(AutomatedUwRecommendation)
    };
}
