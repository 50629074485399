import { UserType } from '@api';
import NotAllowedPage from '@views/NotAllowedPage';
import { ComponentType } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useTryGetCurrentAccount, useTryGetCurrentUser } from './hooks';


export function withAuthentication(
    Component: ComponentType, authorizedTypes?: UserType[]
) {
    return function AuthenticationWrapper() {
        const user = useTryGetCurrentUser();
        const account = useTryGetCurrentAccount();
        const { pathname } = useLocation();

        return !user ? (
            <Navigate
                to="/login"
                state={{ redirectTo: pathname }}
            />
        ) : ((account && authorizedTypes?.includes(account?.userType)) || !authorizedTypes) ? (
            <Component />
        ) : (
            <NotAllowedPage />
        );
    };
}
