import api, {
    Loan, LoanDocument, LoanEvent, LoanStatus, loanStatusDisplay
} from '@api';
import { CheckCircleOutline, Description, OpenInNew } from '@mui/icons-material';
import {
    Alert, Button, Grow, IconButton, LinearProgress, Link as MuiLink, Tooltip, Typography
} from '@mui/material';
import {
    CurrencyTypography, ExpandableCard, FileInput, IconTypography, LabeledValue, ProgressIndicator
} from '@tsp-ui/core/components';
import { isPastDate, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import ExpiredButton from '@views/product-pricing/components/ExpiredButton';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import {
    Dispatch, SetStateAction, useCallback, useState
} from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import styles from './LoanPipelineResultCard.module.scss';
import { LoanTimeline } from './LoanTimeline';


interface LoanPipelineResultCardProps {
    loan: Loan;
    updateLoan: (loan: Loan) => void;
    onOpenLoanDetails: () => void;
    onOpenLoanDocs: () => void;
}

export default function LoanPipelineResultCard({
    loan, updateLoan, onOpenLoanDetails, onOpenLoanDocs
}: LoanPipelineResultCardProps) {
    const {
        id, loanNumber, loanAmount, interestRate, borrowerName, loanStatus, expirationDate
    } = loan;

    const [ loanDocuments, setLoanDocuments ] = useState<LoanDocument[]>();

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => { // load data for card
        try {
            setLoanDocuments(
                (await api.document.getLoanDocuments(id)).filter(({ instanceCount }) => instanceCount === 1)
            );
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan documents', error);
        }

        setDocsLoading(false);
    }, [ id, pageMessage ]));

    const [ eventsLoading, setEventsLoading ] = useState(true);
    const [ docsLoading, setDocsLoading ] = useState(true);

    const [ loanEvents, setLoanEvents ] = useState<LoanEvent[]>();
    const [ showFetchError, setShowFetchError ] = useState(false);

    useAsyncEffect(useCallback(async () => { // load data for expanded content
        try {
            const loanEvents = await api.loans.getLoanEvents(id);
            setLoanEvents(loanEvents);
        } catch (error) {
            setShowFetchError(true);
        }

        setEventsLoading(false);
    }, [ id ]));

    return (
        <ExpandableCard
            className={styles.root}
            loading={eventsLoading}
            expandedContent={showFetchError ? (
                <Alert // TODO post-demo maybe show sub-messages as well
                    severity="error"
                    className={styles.alert}
                >
                    An error occurred while fetching the loan information
                </Alert>
            ) : (
                <LoanCardContent
                    loan={loan}
                    loanEvents={loanEvents || []}
                    setLoanEvents={setLoanEvents}
                    setLoanDocuments={setLoanDocuments}
                    updateLoan={updateLoan}
                />
            )}
            indentContent
        >
            <div className={styles.mainRow}>
                <Typography
                    color="textSecondary"
                    className={styles.loanNumber}
                >
                    Loan #

                    <MuiLink
                        component={Link}
                        to={loan.id}
                    >
                        {loanNumber}
                    </MuiLink>

                    <Tooltip title="View loan data">
                        <IconButton onClick={onOpenLoanDetails}>
                            <OpenInNew
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="View loan documents">
                        <IconButton onClick={onOpenLoanDocs}>
                            <Description
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                </Typography>

                <div>
                    <CurrencyTypography
                        value={loanAmount}
                        variant="body2"
                    />

                    <Typography variant="body2">
                        <span className={styles.hidden}>$</span>{interestRate.toFixed(3)}%
                    </Typography>
                </div>

                <LabeledValue
                    label="Borrower"
                    value={borrowerName}
                    variant="vertical"
                />

                <div className={styles.additionalDetails}>
                    {docsLoading ? (<ProgressIndicator className={styles.docsLoading} />) : (
                        <IconTypography
                            className={styles.iconTypography}
                            icon={(
                                <Tooltip title={`${loanDocuments?.length || 'No'} document${loanDocuments?.length === 1 ? '' : 's'} indexed`}>
                                    <Description color="primary" />
                                </Tooltip>
                            )}
                        >
                            {loanDocuments?.length}
                        </IconTypography>
                    )}

                    <div className={styles.additionalContent}>
                        {isPastDate(expirationDate) && (
                            <ExpiredButton
                                onReprice={async () => {}} // TODO post-demo
                                expirationDate={expirationDate}
                            />
                        )}

                        <div>
                            <Typography
                                variant="caption"
                                align="right"
                                component="div"
                            >
                                {
                                    /* TODO post-demo hard coded for now in lieu of fetching a real
                                    status update after a document upload */
                                    loanStatusDisplay[(loanStatus === LoanStatus.AWAITING_DOCS && loanDocuments?.length)
                                        ? LoanStatus.IN_SETUP
                                        : loanStatus]
                                }
                            </Typography>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                component="div"
                                align="right"
                            >
                                {isPastDate(expirationDate) ? 'Lock expired' : `Lock expires in ${formatDistanceToNowStrict(parseISO(expirationDate))}`}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </ExpandableCard>
    );
}

interface LoanCardContentProps {
    loan: Loan;
    updateLoan: (loan: Loan) => void;
    setLoanDocuments: Dispatch<SetStateAction<LoanDocument[] | undefined>>;
    loanEvents: LoanEvent[];
    setLoanEvents: Dispatch<SetStateAction<LoanEvent[] | undefined>>;
}

function LoanCardContent({
    loan, updateLoan, setLoanDocuments, loanEvents, setLoanEvents
}: LoanCardContentProps) {
    const { id, loanStatus } = loan;

    // TODO post-demo implement the actual workflow for uploading additional docs
    const [ addAnotherDoc, setAddAnotherDoc ] = useState(false);

    const [ fileAdded, setFileAdded ] = useState(false);
    const [ indexingComplete, setIndexingComplete ] = useState(false);
    const [ debouncedIndexingComplete ] = useDebounce(indexingComplete, 1000);
    const fileLoading = fileAdded && !indexingComplete;

    const pageMessage = usePageMessage();

    async function handleUpload(files: File[]) {
        setFileAdded(true);

        const formData = new FormData();
        files.forEach(file => formData.append('files', file));

        try {
            await api.document.uploadDocument(id, formData);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading a loan document', error);
        }
    }

    async function onUploadComplete() {
        await api.loans.updateLoanStatus(id, LoanStatus.IN_SETUP);
        setLoanDocuments(await api.document.getLoanDocuments(id));
        setLoanEvents(await api.loans.getLoanEvents(id));
        setIndexingComplete(true);

        updateLoan({
            ...loan,
            loanStatus: LoanStatus.IN_SETUP
        });
        // TODO post-demo refetch the loan itself to check for a new status
    }

    return loanStatus === LoanStatus.AWAITING_DOCS || loanStatus === LoanStatus.IN_SETUP ? (
        <div className={styles.expandableContent}>
            <LoanTimeline
                loanEvents={loanEvents}
                isAwaitingDocs={loanStatus === LoanStatus.AWAITING_DOCS && !fileAdded}
                isIndexing={fileLoading}
                isInProgress={
                    (fileAdded && debouncedIndexingComplete) || (!fileAdded && loanStatus === LoanStatus.IN_SETUP)
                }
            />

            {!addAnotherDoc && (loanStatus === LoanStatus.IN_SETUP || indexingComplete) ? (
                <Grow
                    in
                    timeout={1500}
                >
                    <div className={styles.fileUploadContainer}>
                        <CheckCircleOutline
                            fontSize="large"
                            color="success"
                        />

                        <Typography>
                            Initial doc package uploaded
                        </Typography>

                        <Button
                            className={styles.addAnotherButton}
                            onClick={() => setAddAnotherDoc(true)}
                        >
                            Add another document
                        </Button>
                    </div>
                </Grow>
            ) : (
                <div className={styles.fileUploadContainer}>
                    <Typography>
                        {addAnotherDoc ? 'Upload an additional document' : 'Upload the initial doc package for this loan'}
                    </Typography>

                    <div className={styles.fileInputContainer}>
                        <FileInput
                            title="file"
                            acceptedFileTypes={fileTypes}
                            disabled={fileLoading}
                            single // TODO post-demo change this back to accept multiple
                            onAddFiles={newFiles => {
                                if (newFiles.length) {
                                    handleUpload(newFiles);

                                    // TODO post-demo remove mock
                                    // api.webSocket.subscribe('UPLOAD_COMPLETE', onUploadComplete)
                                    setTimeout(onUploadComplete, 7000);
                                }
                            }}
                        />

                        {fileLoading && (
                            <div className={styles.fileUploadLoader}>
                                <LinearProgress />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    ) : null;
}

const fileTypes = [ 'pdf' ];
