import {
    Address, ClientInvestor, InvestorStatus, ManagedEligibilityStatus, State
} from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';


export const mocks = [
    rest.get(getMockUrl('/client/:clientID/investor'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.url.searchParams.get('showAgencyOnly') === 'true'
            ? agencyInvestors
            : investors)
    ))),
    rest.put(getMockUrl('/client/:clientID/investor/:investorID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/client/:clientID/investor'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json({
            ...req.body as ClientInvestor,
            id: 1
        })
    )),
    rest.post(getMockUrl('/investor/managed'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...agencyInvestors.find(investor => `${investor.id}` === req.params.investorID),
            ...req.body as ClientInvestor
        })
    ))),
    rest.patch(getMockUrl('/client/:clientID/investor/:investorID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...privateInvestors.find(investor => `${investor.id}` === req.params.investorID),
            ...req.body as ClientInvestor
        })
    )))
];

const { ACTIVE, PENDING, INACTIVE } = InvestorStatus;
const { MANAGED, UNMANAGED } = ManagedEligibilityStatus;

const addresses: Address[] = [
    {
        street: '1234 Mystery Lane',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    }
];

let investorID = 30;
export const agencyInvestors: ClientInvestor[] = [
    {
        id: `${investorID++}`,
        code: 'FNMA',
        name: 'Fannie Mae',
        legalName: 'Federal National Mortgage Association',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [],
        status: ACTIVE,
        addresses,
        managedInvestorIsActive: true,
        sellerServicerNumber: '12345'
    },
    {
        id: `${investorID++}`,
        code: 'FHLMC',
        name: 'Freddie Mac',
        legalName: 'Federal Home Loan Mortgage Corporation',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [],
        status: INACTIVE,
        addresses,
        managedInvestorIsActive: false,
        sellerServicerNumber: '98765'
    },
    {
        id: `${investorID++}`,
        code: 'GNMA',
        name: 'Ginnie Mae',
        legalName: 'Government National Mortgage Association',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [ '1' ],
        status: INACTIVE,
        addresses,
        managedInvestorIsActive: false,
        sellerServicerNumber: '01010101'
    }
];

export const privateInvestors: ClientInvestor[] = [
    {
        id: `${investorID++}`,
        code: 'QQQ',
        name: 'Private Fund',
        legalName: 'Private Fund Inc',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        managedInvestorIsActive: false,
        activeClientIDs: [],
        status: ACTIVE,
        addresses,
        eligibilityBasedOnInvestorId: '2'
    },
    {
        id: `${investorID++}`,
        code: 'XFH',
        name: 'Private Bank',
        legalName: 'Private Bank Corp',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        managedInvestorIsActive: false,
        activeClientIDs: [],
        status: ACTIVE,
        addresses
    },
    {
        id: `${investorID++}`,
        code: 'FFR',
        name: 'Private Investor',
        legalName: 'Private Investment Co',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        managedInvestorIsActive: false,
        activeClientIDs: [],
        status: INACTIVE,
        addresses,
        eligibilityBasedOnInvestorId: '1'
    },
    {
        id: `${investorID++}`,
        code: 'KXQ',
        name: 'Private Institution',
        legalName: 'Private Inst Corp',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        managedInvestorIsActive: false,
        activeClientIDs: [],
        status: INACTIVE,
        addresses
    },
    {
        id: `${investorID++}`,
        code: 'NEWI',
        name: 'New Institution',
        legalName: 'New Inst Corp',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        managedInvestorIsActive: false,
        activeClientIDs: [],
        status: PENDING,
        addresses
    }
];

const investors = agencyInvestors.concat(privateInvestors);
