import { Account, Role, UserType } from '@api';
import { createReducer } from '@reduxjs/toolkit';

import logoUrl from '../../images/logo-full.png';

import { authenticateSession, logout } from './';


interface AuthenticatedUser {
    firstName: string;
    lastName: string;
    email: string;
    internalRoles?: Role[];
}

export interface AuthenticationState {
    initNeeded: boolean;
    user: AuthenticatedUser | undefined;
    accounts: Account[] | undefined;
}

const initialState: AuthenticationState = {
    initNeeded: true,
    user: undefined,
    accounts: undefined
};

export const reducer = createReducer(initialState, (builder) => builder
    .addCase(authenticateSession.rejected, (state) => {
        state.initNeeded = false;
    })
    .addCase(authenticateSession.fulfilled, (state, action) => {
        const { accounts, user } = action.payload;

        return {
            initNeeded: false,
            accounts: [
                ...(user.userType === UserType.INTERNAL ? [
                    {
                        id: 'internal',
                        name: 'Premicorr',
                        logoUrl,
                        userType: UserType.INTERNAL
                    }
                ] : []),
                ...accounts
            ],
            user
        };
    })
    .addCase(logout.fulfilled, (state) => {
        state.user = undefined;
    }));
