import {
    Override,
    getItemById,
    getRandomEnumValue,
    getRandomItemFromArray,
    getRandomValueFromObject,
    randomBoolean,
    randomNum
} from '@tsp-ui/core/utils';
import { isBooleanLoanProperty, isEnumLoanProperty } from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/HighLevelGuidelineCard';
import { rest } from 'msw';

import {
    Address,
    AmortizationType,
    AutomatedUwSystem,
    CommonLoanProgram,
    CreateLoanProgramReqBody,
    CreateLoanProgramResBody,
    EligibilityExclusion,
    EligibilityExclusions,
    EligibilityGuideline,
    EligibilityGuidelineSet,
    EligibilityGuidelineSetSummary,
    ExclusionEqualityType,
    HighLevelGuideline,
    InternalLoanProgram,
    Investor,
    InvestorStatus,
    LoanProgramStatus,
    LoanProperty,
    LoanPurpose,
    LoanType,
    ManagedEligibilityStatus,
    NumUnits,
    NumericFieldOperator,
    OccupancyType,
    Operator,
    PropertyType,
    SpecialtyProduct,
    State,
    loanPropertyEnumDisplays
} from '..';
import { getMockUrl } from '../../mocks/getMockUrl';
import { agencyInvestors, privateInvestors } from '../client/investor/clientInvestor-mocks';


const { MANAGED, UNMANAGED, PENDING } = ManagedEligibilityStatus;
const {
    BETWEEN, GREATER_THAN, GREATER_THAN_EQUAL, LESS_THAN, LESS_THAN_EQUAL
} = NumericFieldOperator;

let investorID = 1;
let loanProgramID = 0;
let eligibilityGuidelineSetID = 100;
let exclusionID = 0;

export const mocks = [
    rest.get(getMockUrl('/investor'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(investors)
    ))),
    rest.put(getMockUrl('/investor/:investorID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/investor'), (req, res, ctx) => {
        const newInvestor = {
            ...req.body as Investor,
            id: `${investorID++}`,
            managedEligibilityStatus: ManagedEligibilityStatus.UNMANAGED
        };

        investors.push(newInvestor);

        return res(
            ctx.status(200),
            ctx.json(newInvestor)
        );
    }),
    rest.patch(getMockUrl('/investor/:investorID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...investors.find(investor => investor.id === req.params.investorID),
            ...req.body as Investor
        })
    ))),
    rest.get(getMockUrl('/investor/:investorID/loan-program'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(getLoanPrograms(req.params.investorID as string, !!req.headers.get('client-token')))
    ))),
    rest.post(getMockUrl('/investor/:investorID/loan-program'), (req, res, ctx) => {
        const { loanProgram, eligibilityGuidelineSet } = req.body as CreateLoanProgramReqBody;

        const loanProgramId = `${loanProgramID++}`;

        const response: CreateLoanProgramResBody = {
            loanProgram: {
                ...loanProgram,
                id: loanProgramId,
                status: LoanProgramStatus.ACTIVE
            },
            eligibilityGuidelineSet: {
                ...eligibilityGuidelineSet,
                id: `${eligibilityGuidelineSetID++}`,
                loanProgramId
            }
        };

        return res(
            ctx.status(200),
            ctx.json(response)
        );
    }),
    rest.put(getMockUrl('/investor/:investorID/loan-program/:loanProgramID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.patch(getMockUrl('/investor/:investorID/loan-program/:loanProgramID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...loanProgramsBase.find(loanProgram => loanProgram.id === req.params.loanProgramID),
            ...req.body as InternalLoanProgram,
            investorId: req.params.investorID
        })
    ))),
    rest.get(getMockUrl('/investor/:investorID/loan-program/:loanProgramID/guideline-set'), (req, res, ctx) => {
        let responseBody: EligibilityGuidelineSetSummary[] = [];
        // for client requests, only return an overlay for the agencyInvestors
        if (req.headers.get('client-token')) {
            const investor = getItemById(investors, req.params.investorID as string);
            if (!investor.isAgency) {
                responseBody = eligibilityGuidelineSetSummaries;
            } else {
                responseBody = randomBoolean()
                    ? eligibilityGuidelineSetSummaries.map((guidelineSet, index) => ({
                        ...guidelineSet,
                        isOverlay: randomBoolean()
                    }))
                    : [
                        {
                            ...eligibilityGuidelineSetSummaries[0],
                            isOverlay: false
                        }
                    ];
            }
        } else {
            responseBody = eligibilityGuidelineSetSummaries;
        }
        return (res(
            ctx.status(200),
            ctx.json(responseBody)
        ));
    }),
    rest.post(getMockUrl('/investor/:investorID/loan-program/:loanProgramID/guideline-set'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json({
            ...req.body as Omit<EligibilityGuidelineSet, 'id'>,
            id: `${eligibilityGuidelineSetID++}`
        })
    )),
    rest.get(getMockUrl('/investor/:investorID/loan-program/:loanProgramID/guideline-set/:guidelineSetID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json<EligibilityGuidelineSet>({
            ...getItemById(eligibilityGuidelineSetSummaries, req.params.guidelineSetID as string),
            highLevelGuidelines,
            guidelines: eligibilityGuidelines,
            exclusions
        })
    ))),
    rest.delete(getMockUrl('/investor/:investorID/loan-program/:loanProgramID/guideline-set/:guidelineSetID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.put(getMockUrl('/investor/:investorID/loan-program/:loanProgramID/guideline-set/:guidelineSetID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.patch(getMockUrl('/investor/:investorID/loan-program/:loanProgramID/guideline-set/:guidelineSetID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...eligibilityGuidelineSetSummaries.find(
                eligibilityGuidelineSet => eligibilityGuidelineSet.id === req.params.guidelineSetID
            ),
            ...req.body as EligibilityGuidelineSet
        })
    ))),
    rest.get(getMockUrl('/investor/:investorID/eligibility-exclusions'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(exclusions)
    ))),
    rest.post(getMockUrl('/investor/:investorID/eligibility-exclusions'), (req: { body: Override<EligibilityExclusions, { exclusions: Omit<EligibilityExclusion, 'id'>[] }> }, res, ctx) => (res(
        ctx.status(200),
        ctx.json<EligibilityExclusions>({
            ...req.body,
            exclusions: req.body.exclusions.map(exclusion => ({
                id: String(exclusionID++),
                ...exclusion
            }))
        })
    ))),
    rest.put(getMockUrl('/investor/:investorID/eligibility-exclusions'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

const { ACTIVE } = InvestorStatus;
const addresses: Address[] = [
    {
        street: '1234 Mystery Lane',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    },
    {
        street: '234 Orthodox Languin road',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    },
    {
        street: '1 2nd pl',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    },
    {
        street: '361 Elements Place Apt 551D - Unit xyz',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    }
];

const investors: Investor[] = [
    {
        id: `${investorID++}`,
        code: 'FNMA',
        name: 'Fannie Mae',
        legalName: 'Federal National Mortgage Association',
        isAgency: true,
        managedEligibilityStatus: PENDING,
        activeClientIDs: [
            '1', '2', '3'
        ],
        status: ACTIVE,
        addresses
    },
    {
        id: `${investorID++}`,
        code: 'FHLMC',
        name: 'Freddie Mac',
        legalName: 'Federal Home Loan Mortgage Corporation',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [],
        status: ACTIVE,
        addresses
    },
    {
        id: `${investorID++}`,
        code: 'GNMA',
        name: 'Ginnie Mae',
        legalName: 'Government National Mortgage Association',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [ '1' ],
        status: ACTIVE,
        addresses
    }
];

let highLevelGuidelineID = 0;
const highLevelGuidelines: HighLevelGuideline[] = [
    {
        id: `${highLevelGuidelineID++}`,
        loanProperty: LoanProperty.AUS,
        operator: Operator.EQUALS,
        value: AutomatedUwSystem.DU
    },
    {
        id: `${highLevelGuidelineID++}`,
        loanProperty: LoanProperty.FICO,
        operator: Operator.IS_GREATER_THAN_OR_EQUAL_TO,
        value: 750
    },
    {
        id: `${highLevelGuidelineID++}`,
        loanProperty: LoanProperty.LOAN_TYPE,
        operator: Operator.EQUALS,
        value: LoanType.CONVENTIONAL
    }
];

const eligibilityGuidelines: EligibilityGuideline[] = [
    {
        id: '1',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '2',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '3',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '4',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '5',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '6',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '7',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '8',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '9',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '10',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '11',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '12',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '13',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '14',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '15',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '16',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '17',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '18',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '19',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '20',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '21',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '22',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '23',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '24',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '25',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '26',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '27',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '28',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '29',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '30',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '31',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '32',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '33',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '34',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '35',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '36',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '37',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '38',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '39',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '40',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '41',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '42',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '43',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '44',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '45',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '46',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '47',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '48',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '49',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 70,
        minCLTV: 0,
        maxCLTV: 70,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '50',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '51',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '52',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '53',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '54',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '55',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '56',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '57',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '58',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '59',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '60',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '61',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '62',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '63',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '64',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '65',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '66',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '67',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '68',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '69',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '70',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '71',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '72',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 70,
        minCLTV: 0,
        maxCLTV: 70,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '73',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 70,
        minCLTV: 0,
        maxCLTV: 70,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '74',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '75',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '76',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '77',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '78',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '79',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '80',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '81',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '82',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '83',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '84',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '85',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '86',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '87',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '88',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '89',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '90',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '91',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '92',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '93',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '94',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '95',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '96',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '97',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '98',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '99',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '100',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '101',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '102',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '103',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '104',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '105',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '106',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '107',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '108',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 97,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '109',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '110',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 90,
        minCLTV: 0,
        maxCLTV: 90,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '111',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '112',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 85,
        minCLTV: 0,
        maxCLTV: 85,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '113',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '114',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '115',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '116',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '117',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '118',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '119',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '120',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '121',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '122',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '123',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '124',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '125',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 70,
        minCLTV: 0,
        maxCLTV: 70,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '126',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '127',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '128',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '129',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '130',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '131',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '132',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '133',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '134',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '135',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '136',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.PURCHASE,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '137',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '138',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '139',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '140',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 97,
        minCLTV: 0,
        maxCLTV: 105,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '141',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.ONE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '142',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.PUD,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '143',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '144',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: true
    },
    {
        id: '145',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.TWO,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '146',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '147',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_RATE_TERM,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 95,
        minCLTV: 0,
        maxCLTV: 95,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '148',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.THREE,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 70,
        minCLTV: 0,
        maxCLTV: 70,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '149',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: NumUnits.FOUR,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 70,
        minCLTV: 0,
        maxCLTV: 70,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '150',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 80,
        minCLTV: 0,
        maxCLTV: 80,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '151',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.SECOND_HOME,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    },
    {
        id: '152',
        guidelineSetId: '1',
        loanType: LoanType.CONVENTIONAL,
        occupancy: OccupancyType.INVESTMENT,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        amortType: AmortizationType.FIXED,
        propertyType: PropertyType.CONDO,
        specialtyProduct: SpecialtyProduct.HOME_POSSIBLE,
        aus: AutomatedUwSystem.DU,
        units: undefined,
        minFICO: 620,
        maxFICO: 999,
        minLTV: 0,
        maxLTV: 75,
        minCLTV: 0,
        maxCLTV: 75,
        minDTI: 0,
        maxDTI: 99,
        minTerm: 85,
        maxTerm: 360,
        minReservesMonths: 3,
        highBalance: false
    }
];

export const exclusions: EligibilityExclusions = {
    operator: 'or',
    exclusions: [ ...Array(randomNum(2, 6)) ].map(() => getExclusion()),
    groups: [ ...Array(randomNum(1, 4)) ].map(() => ({
        exclusions: [ ...Array(randomNum(2, 6)) ].map(() => getExclusion())
    }))
};

function getExclusion(): EligibilityExclusion {
    const loanProperty = getRandomEnumValue(LoanProperty);
    const operator = getRandomEnumValue(NumericFieldOperator);

    const min = [
        BETWEEN, GREATER_THAN, GREATER_THAN_EQUAL
    ].includes(operator) ? randomNum(300, 400) : undefined;
    const max = [
        BETWEEN, LESS_THAN, LESS_THAN_EQUAL
    ].includes(operator) ? randomNum(400, 850) : undefined;

    let value: EligibilityExclusion['value'];
    if (isEnumLoanProperty(loanProperty)) {
        value = [ getRandomValueFromObject(loanPropertyEnumDisplays[loanProperty]!) ];
    } else if (isBooleanLoanProperty(loanProperty)) {
        value = randomBoolean();
    } else {
        value = {
            operator,
            min,
            max
        };
    }

    return {
        id: `${exclusionID++}`,
        loanProperty,
        value,
        equalityType: (isEnumLoanProperty(loanProperty) || isBooleanLoanProperty(loanProperty))
            ? getRandomEnumValue(ExclusionEqualityType)
            : undefined
    };
}

const eligibilityGuidelineSetSummaries: EligibilityGuidelineSetSummary[] = [
    {
        id: `${eligibilityGuidelineSetID++}`,
        loanProgramId: '1',
        effectiveDate: new Date('12/14/2021').toISOString(),
        expirationDate: new Date('12/14/2023').toISOString()
    },
    {
        id: `${eligibilityGuidelineSetID++}`,
        loanProgramId: '1',
        effectiveDate: new Date('1/5/2021').toISOString(),
        expirationDate: new Date('10/14/2022').toISOString()
    },
    {
        id: `${eligibilityGuidelineSetID++}`,
        loanProgramId: '1',
        effectiveDate: new Date('1/5/2021').toISOString(),
        expirationDate: new Date('08/14/2022').toISOString()
    },
    {
        id: `${eligibilityGuidelineSetID++}`,
        loanProgramId: '1',
        effectiveDate: new Date('1/5/2021').toISOString(),
        expirationDate: new Date('05/14/2022').toISOString()
    },
    {
        id: `${eligibilityGuidelineSetID++}`,
        loanProgramId: '1',
        effectiveDate: new Date('12/14/2023').toISOString(),
        expirationDate: new Date('12/14/2025').toISOString()
    }
];

export const loanProgramsBase: Omit<InternalLoanProgram, 'investorId'>[] = [
    {
        id: `${loanProgramID++}`,
        name: 'Standard Eligibility Requirements DU',
        description: 'Eligibility for loans using Desktop Underwriter excluding High LTV Refinance, HomeReady, HomeStyle Renovation, and Manufactured Housing',
        status: LoanProgramStatus.ACTIVE
    },
    {
        id: `${loanProgramID++}`,
        name: 'HomeStyle Renovation DU',
        description: 'Eligibility for HomeStyle Renovation loans using Desktop Underwriter',
        status: LoanProgramStatus.ACTIVE
    },
    {
        id: `${loanProgramID++}`,
        name: 'Manufactured Housing DU',
        description: 'Eligibility for Manufactured Housing loans using Desktop Underwriter',
        status: LoanProgramStatus.ACTIVE
    },
    {
        id: `${loanProgramID++}`,
        name: 'HomeReady DU',
        description: 'Eligibility for HomeReady loans using Desktop Underwriter',
        status: LoanProgramStatus.ACTIVE
    },
    {
        id: `${loanProgramID++}`,
        name: 'High LTV Refinance DU',
        description: 'Eligibility for High LTV Refinance loans using Desktop Underwriter',
        status: LoanProgramStatus.ACTIVE
    },
    {
        id: `${loanProgramID++}`,
        name: 'A Custom Loan Program',
        description: 'Eligibility for a custom Loan Program',
        status: LoanProgramStatus.ACTIVE
    },
    {
        id: `${loanProgramID++}`,
        name: 'Standard Eligibility Requirements Manual',
        description: 'Eligibility for loans that are manually underwritten excluding High LTV Refinance, HomeReady, HomeStyle Renovation, and Manufactured Housing',
        status: LoanProgramStatus.ACTIVE
    },
    {
        id: `${loanProgramID++}`,
        name: 'HomeStyle Renovation Manual',
        description: 'Eligibility for HomeStyle Renovation loans that are manually underwritten',
        status: LoanProgramStatus.ACTIVE
    },
    {
        id: `${loanProgramID++}`,
        name: 'Manufactured Housing Manual',
        description: 'Eligibility for Manufactured Housing loans that are manually underwritten',
        status: LoanProgramStatus.ACTIVE
    },
    {
        id: `${loanProgramID++}`,
        name: 'HomeReady Manual',
        description: 'Eligibility for HomeReady loans using that are manually underwritten',
        status: LoanProgramStatus.ACTIVE
    },
    {
        id: `${loanProgramID++}`,
        name: 'High LTV Refinance Manual',
        description: 'Eligibility for High LTV Refinance loans that are manually underwritten',
        status: LoanProgramStatus.ACTIVE
    }
];

function getLoanPrograms(investorId: string, isClient?: boolean): CommonLoanProgram[] {
    const loanProgramInvestors = isClient ? [ ...privateInvestors, ...agencyInvestors ] : investors;

    return getItemById(loanProgramInvestors, investorId).managedEligibilityStatus === UNMANAGED
        ? loanProgramsBase.map(loanProgram => ({
            ...loanProgram,
            investorId
        }))
        : loanProgramsBase.map(loanProgram => ({
            ...loanProgram,
            investorId,
            // for mocks, agency investors can inherit eligibility, private investors won't
            baseLoanProgramId: getItemById(loanProgramInvestors, investorId).isAgency
                ? getRandomItemFromArray(loanProgramsBase).id
                : undefined
        }));
}
