import { mocks as authMocks } from '../api/auth/auth-mocks';
import { mocks as clientMocks } from '../api/client/client-mocks';
import { mocks as documentMocks } from '../api/document/document-mocks';
import { mocks as overlayMocks } from '../api/exclusion-overlay/exclusion-overlay-mocks';
import { mocks as investorMocks } from '../api/investor/investor-mocks';
import { mocks as loanMocks } from '../api/loan/loan-mocks';
import { mocks as notificationMocks } from '../api/notification/notification-mocks';
import { mocks as pricingMocks } from '../api/pricing/pricing-mocks';
import { mocks as productsMocks } from '../api/products/products-mocks';
import { mocks as purchaseAdviceMocks } from '../api/purchase-advice/purchaseAdvice-mocks';
import { mocks as referenceGudeMocks } from '../api/reference-guides/referenceGuides-mocks';
import { mocks as notificationsMocks } from '../api/role/notifications/notifications-mocks';
import { mocks as roleMocks } from '../api/role/role-mocks';
import { mocks as userMocks } from '../api/user/user-mocks';

import { mocks as availabilityMocks } from './availability/availability-mocks';
import { mocks as customerMocks } from './customer/customer-mocks';


export const mocks = [
    ...authMocks,
    ...availabilityMocks,
    ...documentMocks,
    ...investorMocks,
    ...userMocks,
    ...roleMocks,
    ...notificationMocks,
    ...purchaseAdviceMocks,
    ...referenceGudeMocks,
    ...notificationsMocks,
    ...clientMocks,
    ...customerMocks,
    ...loanMocks,
    ...overlayMocks,
    ...pricingMocks,
    ...productsMocks
];
