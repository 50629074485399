import api, { PurchaseAdviceFee, purchaseAdviceFeeNameDisplay } from '@api';
import { Button, DialogActions, DialogContent } from '@mui/material';
import {
    CurrencyField, RoutedDialog, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { replaceItemById, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './PurchaseAdviceFeeDialog.module.scss';
import { PurchaseAdviceContext } from './PurchaseAdviceManagementPage';


export function PurchaseAdviceFeeDialog() {
    const { feeID } = useParams<{feeID: string}>();
    const { purchaseAdviceFees, setPurchaseAdviceFees } = useContext(PurchaseAdviceContext);
    const defaultValues = purchaseAdviceFees.find(fee => fee.id.toString() === feeID);
    const formMethods = useForm<PurchaseAdviceFee>({ defaultValues });
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const [ loading, setLoading ] = useState(false);

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setLoading(true);

        try {
            const newFee = feeID
                ? await api.purchaseAdvice.updatePurchaseAdviceFee(formData)
                : await api.purchaseAdvice.addPurchaseAdviceFee(formData);

            setPurchaseAdviceFees(feeID
                ? replaceItemById(purchaseAdviceFees, newFee)
                : [ ...purchaseAdviceFees, newFee ]);

            navigate('..');

            pageMessage.success('Fee saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the fee', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            closeTo=".."
            title={`${feeID ? 'Edit' : 'Add'} fee`}
            loading={loading}
        >
            <DialogContent>
                <form
                    id={PurchaseAdviceFeeDialog.formID}
                    onSubmit={handleSubmit}
                    className={styles.root}
                    noValidate
                >
                    <FormProvider {...formMethods}>
                        <TextField<PurchaseAdviceFee>
                            name="feeName"
                            label="Fee name"
                            disabled={!!defaultValues}
                            select
                            required
                        >
                            {renderEnumOptions(purchaseAdviceFeeNameDisplay)}
                        </TextField>

                        <CurrencyField<PurchaseAdviceFee>
                            name="feeAmount"
                            label="Fee amount"
                            required
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    type="submit"
                    form={PurchaseAdviceFeeDialog.formID}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

PurchaseAdviceFeeDialog.formID = 'add-edit-purchase-advice-fee-form';
