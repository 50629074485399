import api, { User, UserType } from '@api';
import {
    AccountCircleOutlined, Close, RemoveCircleOutline, Save
} from '@mui/icons-material';
import {
    Button, DialogContent, IconButton, InputAdornment, MenuItem, Paper, TextField, Tooltip, Typography
} from '@mui/material';
import {
    DialogActions, PhoneTypography, RoutedDialog, RoutedDialogProps
} from '@tsp-ui/core/components';
import {
    getFullName, useAsyncEffect, useConfirm, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useCallback, useContext, useState } from 'react';

import { AdminRouteParams } from '../../../components/AdminPageTemplate';
import { ClientDetailsContext, ClientDetailsContextValue } from '../ClientDetailPage';

import styles from './AuthorizedUserDialog.module.scss';


export default function AuthorizedUserDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const [ userOptions, setUserOptions ] = useState<User[]>([]);
    const [ selectedUserID, setSelectedUserID ] = useState('');

    const { clientID } = useParams<AdminRouteParams<'client'>>();

    const [ isAdding, setIsAdding ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const {
        authorizedUsers, updateAuthorizedUsers
    } = useContext(ClientDetailsContext) as Required<ClientDetailsContextValue>;

    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    async function onDelete(userID: string) {
        setLoading(true);

        if (clientID && await confirm('Are you sure you want to deauthorize this user?')) {
            try {
                await api.client.deleteAuthorizedPremicorrUser(clientID, userID);
                updateAuthorizedUsers(authorizedUsers.filter(user => user.id !== userID));

                pageMessage.success('User deauthorized');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deauthorizing the user', error);
            }
        }

        setLoading(false);
    }

    async function onSave() {
        setLoading(true);

        const newUser = userOptions.find(user => user.id === selectedUserID);

        if (clientID && newUser) {
            try {
                updateAuthorizedUsers(
                    authorizedUsers.concat([ await api.client.addAuthorizedPremicorrUser(clientID, newUser) ])
                );

                setIsAdding(false);

                pageMessage.success('User authorized');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while authorizing the user', error);
            }
        }

        setLoading(false);
    }

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setUserOptions((await api.users.getUsers(UserType.INTERNAL)).filter(
                userOption => !authorizedUsers.some(({ id }) => id === userOption.id)
            ));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching available internal users', error);
        }

        setLoading(false);
    }, [ authorizedUsers, pageMessage ]));

    return (
        <RoutedDialog
            {...props}
            title="Authorized premicorr users"
            maxWidth={false}
        >
            <DialogContent>
                {(authorizedUsers.length > 0 || isAdding) && (
                    <Paper
                        variant="outlined"
                        className={styles.container}
                    >
                        {authorizedUsers.map((user) => (
                            <AuthorizedUserCard
                                key={user.id}
                                user={user}
                                onDelete={onDelete}
                            />
                        ))}

                        {isAdding && (
                            <div className={styles.input}>
                                <TextField
                                    value={selectedUserID}
                                    onChange={(event) => setSelectedUserID(event.target.value)}
                                    className={styles.field}
                                    variant="standard"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleOutlined />
                                            </InputAdornment>
                                        )
                                    }}
                                    required
                                    autoFocus
                                    select
                                >
                                    {userOptions.map(user => (
                                        <MenuItem
                                            value={user.id}
                                            key={user.id}
                                            className={styles.menuItem}
                                        >
                                            <Typography>
                                                {getFullName(user)}
                                            </Typography>

                                            <Typography variant="body2">
                                                {user.email}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <Tooltip title="Cancel">
                                    <IconButton onClick={() => setIsAdding(false)}>
                                        <Close />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Save">
                                    <IconButton onClick={onSave}>
                                        <Save color="secondary" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </Paper>
                )}
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    onClick={() => setIsAdding(true)}
                    disabled={isAdding || !userOptions.length}
                    variant="contained"
                >
                    Add user
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface AuthorizedUserCardProps {
    user: User;
    onDelete(userID: string): Promise<void>;
}

function AuthorizedUserCard({
    onDelete, user: {
        email, id, phone, ...user
    }
}: AuthorizedUserCardProps) {
    return (
        <Paper
            className={styles.card}
            elevation={0}
        >
            <div>
                <Typography fontWeight={500}>
                    {getFullName(user)}
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {email}
                </Typography>

                <PhoneTypography variant="body2">
                    {phone || ''}
                </PhoneTypography>
            </div>

            <div className={styles.buttons}>
                <Tooltip title="Deauthorize user">
                    <IconButton
                        edge="end"
                        onClick={() => onDelete(id)}
                    >
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
    );
}
