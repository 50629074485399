import api, {
    CreateLoanProgramReqBody,
    EligibilityGuideline,
    EligibilityGuidelineSet,
    EligibilityGuidelineSetSummary,
    LoanProperty
} from '@api';
import {
    Button, DialogContent, FormHelperText, Paper, Step, StepLabel, Stepper
} from '@mui/material';
import {
    DateField, DialogActions, OptionalWrapper, PaperSaveLoader, Switch, TextField
} from '@tsp-ui/core/components';
import {
    ConvertValues,
    DeepPartial,
    booleanObjectsToEnums,
    enumToBoolean,
    isFutureDate,
    isPastDate,
    isToday,
    replaceItemById,
    usePageMessage,
    useParams
} from '@tsp-ui/core/utils';
import { AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import { LoanProgramsContext } from '@views/admin/investors/InternalInvestorDetailRoutes';
import {
    EligibilityExclusionsFormValues,
    ExclusionGroupRow,
    exclusionsToFormValues,
    formValuesToExclusions,
    getEmptyEligibilityExclusions
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { EligibilityGuidelineGrid } from '../../components/EligibilityGuidelineGrid';
import { ProgramEligibilityContext } from '../InternalLoanProgramDetailPage';

import { EligibilityGuidelinesContext } from './EditEligibilityGuidelinesDialog';
import { EditHighLevelGuidelines } from './HighLevelGuidelines';
import { LoanProgramDetailsFields } from './LoanProgramDetailsFields';
import styles from './LoanProgramForm.module.scss';


export type EligibilityGuidelineFormValues = ConvertValues<EligibilityGuideline, boolean | undefined, 'TRUE' | 'FALSE'>;

export interface LoanProgramFormValues extends Omit<CreateLoanProgramReqBody, 'eligibilityGuidelineSet'> {
    eligibilityGuidelineSet: Omit<CreateLoanProgramReqBody['eligibilityGuidelineSet'], 'guidelines' | 'exclusions'> & {
        guidelines: EligibilityGuidelineFormValues[];
        exclusions: EligibilityExclusionsFormValues;
    };
    copyExistingMatrix?: boolean;
}

interface LoanProgramFormProps {
    isEdit?: boolean;
    activeStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>;
    defaultValues?: DeepPartial<LoanProgramFormValues>;
}

export function LoanProgramForm({
    isEdit = false, activeStep, setActiveStep, defaultValues
}: LoanProgramFormProps) {
    const { loanPrograms = [], setLoanPrograms } = useContext(LoanProgramsContext);
    const { eligibilityGuidelineSets = [], setEligibilityGuidelineSets } = useContext(ProgramEligibilityContext);
    const { eligibilityGuidelineSet, setEligibilityGuidelineSet } = useContext(EligibilityGuidelinesContext);

    const { pathname } = useLocation();

    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const { investorID, loanProgramID, guidelineSetID } = useParams<AdminSubRouteParams<'guidelineSet'>>();

    const [ loading, setLoading ] = useState(false);

    const formMethods = useForm<LoanProgramFormValues>({
        defaultValues: defaultValues || {
            eligibilityGuidelineSet: {
                exclusions: getEmptyEligibilityExclusions()
            }
        }
    });

    const copyExistingMatrix = formMethods.watch('copyExistingMatrix');
    const guidelinesValues = formMethods.watch('eligibilityGuidelineSet.guidelines');

    const handleSubmit = formMethods.handleSubmit(async formValues => {
        if (activeStep === 0) {
            try {
                if (copyExistingMatrix) {
                    let guidelineSet = eligibilityGuidelineSet;

                    if (!guidelineSet) {
                        setLoading(true);

                        guidelineSet = await api.investors.getEligibilityGuidelineSetDetail(
                            investorID, loanProgramID, guidelineSetID || eligibilityGuidelineSets.find(
                                guidelineSet => !guidelineSet.isOverlay
                            )!.id
                        );

                        setEligibilityGuidelineSet(guidelineSet);

                        setLoading(false);
                    }

                    formMethods.setValue('eligibilityGuidelineSet.exclusions', exclusionsToFormValues(guidelineSet.exclusions));
                    formMethods.setValue('eligibilityGuidelineSet.highLevelGuidelines', booleanObjectsToEnums(guidelineSet.highLevelGuidelines));
                    formMethods.setValue('eligibilityGuidelineSet.guidelines', booleanObjectsToEnums(guidelineSet.guidelines));
                } else if (guidelinesValues?.length) {
                    // remove the guidelines if user goes back and unchecks copyExistingMatrix
                    formMethods.setValue('eligibilityGuidelineSet.exclusions', getEmptyEligibilityExclusions());
                    formMethods.setValue('eligibilityGuidelineSet.highLevelGuidelines', []);
                    formMethods.setValue('eligibilityGuidelineSet.guidelines', []);
                }
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching guideline set details', error);
            }
        }

        if (activeStep < 2) {
            setActiveStep(activeStep + 1);
        } else {
            const formData = convertFormValues(formValues);

            setLoading(true);

            try {
                if (isEdit) {
                    if (defaultValues && isFutureDate(defaultValues.eligibilityGuidelineSet?.effectiveDate)) {
                        // if this is an edit to PENDING guidelines
                        const updatedGuidelineSet = await api.investors.updateEligibilityGuidelineSet(
                                formData.eligibilityGuidelineSet as EligibilityGuidelineSet, investorID
                        );

                        setEligibilityGuidelineSets(replaceItemById(eligibilityGuidelineSets, updatedGuidelineSet));
                    } else {
                        // if this is an edit to active guidelines, cloning of expired guidelines or creation of overlay
                        const newGuidelineSet = await api.investors.createEligibilityGuidelineSet({
                            ...formData.eligibilityGuidelineSet,
                            loanProgramId: loanProgramID,
                            isOverlay: pathname.includes('investor-eligibility') && !!loanPrograms.find(({ id }) => id === loanProgramID)?.baseLoanProgramId
                        }, investorID);

                        let updatedGuidelineSets = [ ...eligibilityGuidelineSets, newGuidelineSet ];

                        // if the new guideline is set to be active, expire the old one by updating the expirationDate
                        if (guidelineSetIsActive(formData.eligibilityGuidelineSet)) {
                            const activeGuidelineSet = getActiveGuidelineSet(eligibilityGuidelineSets);

                            if (activeGuidelineSet) {
                                updatedGuidelineSets = replaceItemById(updatedGuidelineSets, {
                                    ...activeGuidelineSet,
                                    expirationDate: new Date().toISOString()
                                });
                            }
                        }

                        setEligibilityGuidelineSets(updatedGuidelineSets);
                    }

                    navigate('..');

                    pageMessage.success('Eligibility guidelines saved');
                } else {
                    // if this is a creation of new loanProgram
                    const { loanProgram, eligibilityGuidelineSet } = await api.investors.createLoanProgram({
                        ...formData,
                        eligibilityGuidelineSet: {
                            ...formData.eligibilityGuidelineSet,
                            guidelines: formData.eligibilityGuidelineSet.guidelines.map(
                                guideline => enumToBoolean(guideline, [ 'highBalance' ])
                            )
                        }
                    });

                    setLoanPrograms([ ...loanPrograms, loanProgram ]);

                    const updatedEligibilityGuidelineSets = [ ...eligibilityGuidelineSets, eligibilityGuidelineSet ];

                    setEligibilityGuidelineSets(updatedEligibilityGuidelineSets);

                    navigate(`../loan-programs/${loanProgram.id}`, { state: { eligibilityGuidelineSets: updatedEligibilityGuidelineSets } });

                    pageMessage.success('Loan program created');
                }
            } catch (error) {
                pageMessage.handleApiError('An error occurred while creating the loan program', error);
            }

            setLoading(false);
        }
    });

    return (
        <>
            <OptionalWrapper
                Component={DialogContent}
                renderWrapper={isEdit}
                className={styles.stepperContent}
            >
                <Stepper
                    activeStep={activeStep}
                    className={clsx(styles.stepper, {
                        [styles.matrixStepper]: activeStep === 1 && isEdit,
                        [styles.pageStepper]: !isEdit
                    })}
                >
                    <Step>
                        <StepLabel>
                            Details
                        </StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>
                            Matrix
                        </StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>
                            Exclusions
                        </StepLabel>
                    </Step>
                </Stepper>
            </OptionalWrapper>

            <OptionalWrapper
                Component={DialogContent}
                renderWrapper={isEdit}
                className={clsx(styles.content, { [styles.matrixContent]: activeStep === 1 })}
            >
                <form
                    id="loan-program-form"
                    onSubmit={handleSubmit}
                    noValidate
                    className={clsx({
                        [styles.fullWidth]: activeStep === 1,
                        [styles.matrixFormRoot]: activeStep === 1
                    })}
                >
                    <FormProvider {...formMethods}>
                        {activeStep === 0 ? (
                            <OptionalWrapper
                                Component={Paper}
                                renderWrapper={!isEdit}
                            >
                                <div
                                    className={clsx(styles.detailsFields, {
                                        [styles.createDetails]: !isEdit
                                    })}
                                >
                                    {!isEdit && <LoanProgramDetailsFields />}

                                    <DateField<LoanProgramFormValues>
                                        label="Effective date"
                                        name="eligibilityGuidelineSet.effectiveDate"
                                        helperText="The date that these edits take effect"
                                        required
                                    />

                                    <DateField<LoanProgramFormValues>
                                        label="Expiration date"
                                        name="eligibilityGuidelineSet.expirationDate"
                                        helperText="The date that these edits are set to expire"
                                    />

                                    {isEdit && (
                                        <>
                                            <TextField<LoanProgramFormValues>
                                                name="eligibilityGuidelineSet.comments"
                                                label="Comments"
                                                multiline
                                                rows={2}
                                            />

                                            <div>
                                                <Switch<LoanProgramFormValues>
                                                    name="copyExistingMatrix"
                                                    label="Copy existing matrix"
                                                />

                                                <FormHelperText>
                                                    The eligibility matrix in the next step will be

                                                    {copyExistingMatrix
                                                        ? ' pre-populated with the existing values'
                                                        : ' empty'}
                                                </FormHelperText>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </OptionalWrapper>
                        ) : activeStep === 1 ? (
                            <div className={styles.gridContainer}>
                                <EditHighLevelGuidelines name="eligibilityGuidelineSet.highLevelGuidelines" />

                                <div className={styles.innerGridContainer}>
                                    <EligibilityGuidelineGrid />
                                </div>
                            </div>
                        ) : (
                            <Paper
                                variant="outlined"
                                className={styles.filterContainer}
                            >
                                <ExclusionGroupRow nameBase="eligibilityGuidelineSet.exclusions" />
                            </Paper>
                        )}
                    </FormProvider>
                </form>
            </OptionalWrapper>

            <OptionalWrapper
                Component={DialogActions}
                renderWrapper={isEdit}
                className={styles.dialogActions}
            >
                <div className={styles.buttonContainer}>
                    {activeStep > 0 ? (
                        <Button
                            onClick={() => setActiveStep(activeStep - 1)}
                        >
                            Back
                        </Button>
                    ) : (
                        <Button
                            component={Link}
                            to=".."
                        >
                            Cancel
                        </Button>
                    )}

                    <Button
                        variant="contained"
                        type="submit"
                        form="loan-program-form"
                    >
                        {activeStep === 2 ? 'Submit' : 'Next'}
                    </Button>

                </div>
            </OptionalWrapper>

            <PaperSaveLoader loading={loading} />
        </>
    );
}

function convertFormValues({ copyExistingMatrix, ...formValues }: LoanProgramFormValues): CreateLoanProgramReqBody {
    return {
        ...formValues,
        eligibilityGuidelineSet: {
            ...formValues.eligibilityGuidelineSet,
            highLevelGuidelines: formValues.eligibilityGuidelineSet.highLevelGuidelines?.map(highLevelGuideline => {
                if (highLevelGuideline.loanProperty === LoanProperty.HIGH_BALANCE) {
                    return enumToBoolean(highLevelGuideline, [ 'value' ]);
                } else {
                    return highLevelGuideline;
                }
            }),
            guidelines: formValues.eligibilityGuidelineSet.guidelines?.map(
                guideline => enumToBoolean(guideline, [ 'highBalance' ])
            ),
            exclusions: formValuesToExclusions(formValues.eligibilityGuidelineSet.exclusions)
        }
    };
}

export function getActiveGuidelineSet(eligibilityGuidelineSets?: EligibilityGuidelineSetSummary[]) {
    return eligibilityGuidelineSets?.find(guidelineSet => guidelineSetIsActive(guidelineSet));
}

type GuidelineSetFormValues = CreateLoanProgramReqBody['eligibilityGuidelineSet'];
export function guidelineSetIsActive(
    { effectiveDate, expirationDate }: EligibilityGuidelineSet | EligibilityGuidelineSetSummary | GuidelineSetFormValues
) {
    return (
        isPastDate(effectiveDate) || isToday(effectiveDate)
    ) && (
        !expirationDate || isFutureDate(expirationDate)
    );
}
