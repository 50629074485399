import { LockAvailability, LockAvailabilityHoliday } from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';


let lockAvailabilityHolidayID = 100;

export const mocks = [
    // Client endpoints
    rest.get(getMockUrl('/client/lockAvailability'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(lockAvailability)
    ))),
    rest.get(getMockUrl('/client/lockAvailabilityHoliday'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(lockAvailabilityHolidays)
    ))),
    rest.put(getMockUrl('/client/lockAvailability/:lockAvailabilityID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/client/lockAvailabilityHoliday/:lockAvailabilityHolidayID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/client/lockAvailabilityHoliday'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json({
            ...req.body as LockAvailabilityHoliday,
            lockAvailabilityHolidayID: lockAvailabilityHolidayID++
        })
    ))),
    rest.delete(getMockUrl('/client/lockAvailabilityHoliday/:lockAvailabilityHolidayID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(lockAvailabilityHolidays[0])
    ))),

    // Customer endpoints
    rest.get(getMockUrl('/customer/:customerID/lockAvailability'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(lockAvailability)
    ))),
    rest.get(getMockUrl('/customer/:customerID/lockAvailabilityHoliday'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(lockAvailabilityHolidays)
    ))),
    rest.put(getMockUrl('/customer/:customerID/lockAvailability/:lockAvailabilityID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/customer/:customerID/lockAvailabilityHoliday/:lockAvailabilityHolidayID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/customer/:customerID/lockAvailabilityHoliday'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json({
            ...req.body as LockAvailabilityHoliday,
            lockAvailabilityHolidayID: lockAvailabilityHolidayID++
        })
    ))),
    rest.delete(getMockUrl('/customer/:customerID/lockAvailabilityHoliday/:lockAvailabilityHolidayID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(lockAvailabilityHolidays[0])
    )))
];

const lockAvailability: LockAvailability[] = [
    {
        lockAvailabilityId: 1,
        isSuspended: false,
        isSuspendedUntilReprice: false,
        suspendedComments: null,
        isMondayAvailable: true,
        mondayStart: '09:00',
        mondayEnd: '18:00',
        isTuesdayAvailable: true,
        tuesdayStart: '09:00',
        tuesdayEnd: '18:00',
        isWednesdayAvailable: true,
        wednesdayStart: '09:00',
        wednesdayEnd: '18:00',
        isThursdayAvailable: true,
        thursdayStart: '09:00',
        thursdayEnd: '18:00',
        isFridayAvailable: true,
        fridayStart: '09:00',
        fridayEnd: '18:00',
        isSaturdayAvailable: false,
        saturdayStart: null,
        saturdayEnd: null,
        isSundayAvailable: false,
        sundayStart: null,
        sundayEnd: null
    }
];

const lockAvailabilityHolidays: LockAvailabilityHoliday[] = [
    {
        lockAvailabilityHolidayId: 1,
        lockAvailabilityDate: '2022-01-17T00:00:00Z',
        isAvailable: true,
        lockStart: '09:00',
        lockEnd: '18:00',
        description: 'Martin Luther King Day'
    },
    {
        lockAvailabilityHolidayId: 10,
        lockAvailabilityDate: '2022-12-26T00:00:00Z',
        isAvailable: false,
        lockStart: null,
        lockEnd: null,
        description: 'Christmas Day (observed)'
    },
    {
        lockAvailabilityHolidayId: 2,
        lockAvailabilityDate: '2022-01-30T00:00:00Z',
        isAvailable: true,
        lockStart: '09:00',
        lockEnd: '18:00',
        description: 'Presidents Day'
    },
    {
        lockAvailabilityHolidayId: 3,
        lockAvailabilityDate: '2022-05-30T00:00:00Z',
        isAvailable: false,
        lockStart: null,
        lockEnd: null,
        description: 'Memorial Day'
    },
    {
        lockAvailabilityHolidayId: 4,
        lockAvailabilityDate: '2022-06-20T00:00:00Z',
        isAvailable: true,
        lockStart: '09:00',
        lockEnd: '18:00',
        description: 'Juneteenth (observed)'
    },
    {
        lockAvailabilityHolidayId: 5,
        lockAvailabilityDate: '2022-07-04T00:00:00Z',
        isAvailable: true,
        lockStart: '09:00',
        lockEnd: '18:00',
        description: 'Independence Day'
    },
    {
        lockAvailabilityHolidayId: 6,
        lockAvailabilityDate: '2022-09-05T00:00:00Z',
        isAvailable: true,
        lockStart: '09:00',
        lockEnd: '18:00',
        description: 'Labor Day'
    },
    {
        lockAvailabilityHolidayId: 7,
        lockAvailabilityDate: '2022-10-10T00:00:00Z',
        isAvailable: false,
        lockStart: null,
        lockEnd: null,
        description: 'Columbus Day'
    },
    {
        lockAvailabilityHolidayId: 8,
        lockAvailabilityDate: '2022-11-11T00:00:00Z',
        isAvailable: false,
        lockStart: null,
        lockEnd: null,
        description: 'Veterans Day'
    },
    {
        lockAvailabilityHolidayId: 9,
        lockAvailabilityDate: '2022-11-24T00:00:00Z',
        isAvailable: false,
        lockStart: null,
        lockEnd: null,
        description: 'Thanksgiving'
    }
];
