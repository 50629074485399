import api, { ProductEventType, ProductHistoryEvent, productEventTypeDisplay } from '@api';
import { TextField } from '@mui/material';
import { PaginatedResponse } from '@tsp-ui/core';
import {
    GroupedTimelineDialog, RoutedDialogProps, ValidatedDateField, renderEnumOptions
} from '@tsp-ui/core/components';
import {
    useAsyncEffect, usePageMessage, usePaginatedQueryParams, useParams
} from '@tsp-ui/core/utils';
import { useCallback, useState } from 'react';

import styles from './ProductHistoryDialog.module.scss';
import ProductHistoryEventCard from './ProductHistoryEventCard';


export function ProductHistoryDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const [ productHistoryItems, setProductHistoryItems ] = useState<PaginatedResponse<ProductHistoryEvent>>();
    const [ loading, setLoading ] = useState(true);
    const { productID } = useParams<{productID: string}>();
    const pageMessage = usePageMessage();

    const [ eventType, setEventType ] = useState<ProductEventType | 'all'>('all');
    const [ dateValue, setDateValue ] = useState<Date | null>(null);

    const [ searchParams, setPageNumber ] = usePaginatedQueryParams({
        date: dateValue && dateValue.toISOString(),
        eventType: eventType === 'all' ? undefined : eventType
    });

    useAsyncEffect(useCallback(async () => {
        try {
            setProductHistoryItems(await api.products.getProductHistory(productID, searchParams));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the product history', error);
        }

        setLoading(false);
    }, [
        pageMessage, productID, searchParams
    ]));

    return (
        <GroupedTimelineDialog<ProductHistoryEvent>
            {...props}
            isRouted
            title="Product history"
            dateKey="date"
            classes={{ paper: styles.root }}
            filterForm={(
                <form className={styles.filters}>
                    <ValidatedDateField
                        label="Date"
                        pickerProps={{ disableFuture: true }}
                        onValidDateChange={setDateValue}
                    />

                    <TextField
                        value={eventType}
                        onChange={event => setEventType(event.target.value as ProductEventType)}
                        label="Event type"
                        select
                    >
                        {renderEnumOptions(productEventTypeDisplay, true)}
                    </TextField>
                </form>
            )}
            renderItem={item => (
                <ProductHistoryEventCard
                    event={item}
                    key={item.id}
                />
            )}
            fullWidth
            paginatedItems={productHistoryItems}
            pageLoading={loading}
            onPageChange={setPageNumber}
        />
    );
}
