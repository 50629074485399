import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { UserType } from '../enums/userTypeEnum';

import { User } from './user-api';


let userID = 1;
export const mocks = [
    // Internal users
    rest.get(getMockUrl('/auth/user'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(internalUsers)
    ))),
    rest.delete(getMockUrl('/auth/user/:userID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.put(getMockUrl('/auth/user/:userID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/auth/user'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as User,
            id: `${userID++}`
        })
    ))),

    // Client users
    rest.get(getMockUrl('/client/user'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(clientUsers)
    ))),
    rest.delete(getMockUrl('/client/user/:userID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.put(getMockUrl('/client/user/:userID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/client/user'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as User,
            id: `${userID++}`
        })
    ))),

    // Customer users
    rest.get(getMockUrl('/customer/:customerID/user'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(customerUsers)
    ))),
    rest.delete(getMockUrl('/customer/:customerID/user/:userID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.put(getMockUrl('/customer/:customerID/user/:userID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/customer/:customerID/user'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as User,
            id: `${userID++}`
        })
    )))
];

export const internalUsers: User[] = [
    {
        id: '838170d6-76cf-45f2-92d0-70b2cf1a3304',
        email: 'joe@premicorr.com',
        firstName: 'Joe',
        lastName: 'Tester',
        phone: '(405) 667-4826',
        type: UserType.INTERNAL,
        isActive: true,
        roleIds: [
            1, 2, 3, 4
        ]
    },
    {
        id: '53630030-1697-4720-82b1-abf28594b063',
        email: 'john@premicorr.com',
        firstName: 'John',
        lastName: 'Tester',
        phone: null,
        type: UserType.INTERNAL,
        isActive: true,
        roleIds: [ 3, 4 ]
    },
    {
        id: '7245449-1697-4720-82b1-abf28594b063',
        email: 'jane@premicorr.com',
        firstName: 'Jane',
        lastName: 'Tester',
        phone: null,
        type: UserType.INTERNAL,
        isActive: true,
        roleIds: [ 1, 2 ]
    },
    {
        id: '23496874-1697-4720-82b1-f8dj48jf',
        email: 'jill@premicorr.com',
        firstName: 'Jill',
        lastName: 'Tester',
        phone: null,
        type: UserType.INTERNAL,
        isActive: true,
        roleIds: [
            1, 3, 4
        ]
    }
];

const clientUsers = internalUsers.map(user => ({
    ...user,
    type: UserType.CLIENT
}));

const customerUsers = internalUsers.map(user => ({
    ...user,
    type: UserType.CUSTOMER
}));
