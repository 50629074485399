import { Account, UserType } from '@api';
import {
    AccountBalance,
    AdminPanelSettings,
    AttachMoney,
    Badge,
    Dashboard,
    Description,
    Groups,
    Inventory,
    LockClock,
    Person,
    PriceCheck,
    SwitchAccount,
    Topic,
    Tune
} from '@mui/icons-material';
import { Slide } from '@mui/material';
import { useTryGetCurrentAccount } from '@utils/hooks';
import clsx from 'clsx';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import logoWhite from '../../../images/logo-white.png';

import styles from './MainNav.module.scss';
import NavItem from './components/NavItem';
import NavList from './components/NavList';
import SubMenuHeaderButton from './components/SubMenuHeaderButton';


const { CLIENT, CUSTOMER, INTERNAL } = UserType;

interface MainNavProps {
    account: Account | undefined;
}

export default function MainNav({ account }: MainNavProps) {
    const { userType } = useTryGetCurrentAccount() || {};

    const [ adminOpen, setAdminOpen ] = useState(false);
    const isAdmin = !!useLocation().pathname.match(/\/accounts\/.*\/admin/);

    const mainNavItems = !account ? (
        <NavItem
            to="/accounts"
            label="Accounts"
            Icon={SwitchAccount}
        />
    ) : (
        <>
            <NavItem
                exact
                to={`/accounts/${account.id}`}
                label="Dashboard"
                Icon={Dashboard}
                onClick={() => setAdminOpen(false)}
                selected={adminOpen ? false : undefined}
            />

            <NavItem
                to={`/accounts/${account.id}/loans`}
                label="Loans"
                Icon={Description}
                onClick={() => setAdminOpen(false)}
                selected={adminOpen ? false : undefined}
            />

            <NavItem
                to={`/accounts/${account.id}/product-pricing`}
                label="Product & Pricing"
                Icon={PriceCheck}
                onClick={() => setAdminOpen(false)}
                selected={adminOpen ? false : undefined}
            />

            {/* <NavItem
                to={`/accounts/${account.id}/reports`}
                label="Reports"
                Icon={Assessment}
                onClick={() => setAdminOpen(false)}
                selected={adminOpen ? false : undefined}
            /> */}

            {/* <NavItem
                to={`/accounts/${account.id}/tools`}
                label="Tools"
                Icon={Build}
                onClick={() => setAdminOpen(false)}
                selected={adminOpen ? false : undefined}
            /> */}

            <NavItem
                selected={adminOpen}
                label="Admin"
                Icon={AdminPanelSettings}
                onClick={() => setAdminOpen(!adminOpen)}
            />
        </>
    );

    const adminNavItems = (
        <>
            {userType === INTERNAL && (
                <NavItem
                    to={`/accounts/${account?.id}/admin/clients`}
                    label="Clients"
                    Icon={Groups}
                    onClick={() => setAdminOpen(false)}
                    dark={adminOpen}
                />
            )}

            {userType === CLIENT && (
                <NavItem
                    to={`/accounts/${account?.id}/admin/customers`}
                    label="Customers"
                    Icon={Groups}
                    onClick={() => setAdminOpen(false)}
                    dark={adminOpen}
                />
            )}

            {userType === INTERNAL && (
                <NavItem
                    to={`/accounts/${account?.id}/admin/investors`}
                    label="Investors"
                    Icon={AccountBalance}
                    onClick={() => setAdminOpen(false)}
                    dark={adminOpen}
                />
            )}

            {userType === CLIENT && (
                <NavItem
                    to={`/accounts/${account?.id}/admin/investor-eligibility`}
                    label="Investor Eligibility"
                    Icon={AccountBalance}
                    onClick={() => setAdminOpen(false)}
                    dark={adminOpen}
                />
            )}

            {userType === CLIENT && (
                <NavItem
                    to={`/accounts/${account?.id}/admin/llpas`}
                    label="LLPAs"
                    Icon={Tune}
                    onClick={() => setAdminOpen(false)}
                    dark={adminOpen}
                />
            )}

            {userType === CLIENT && (
                <NavItem
                    to={`/accounts/${account?.id}/admin/lock-availability`}
                    label="Lock Availability"
                    Icon={LockClock}
                    onClick={() => setAdminOpen(false)}
                    selected={adminOpen ? false : undefined}
                />
            )}

            {userType === CLIENT && (
                <NavItem
                    to={`/accounts/${account?.id}/admin/products`}
                    label="Products"
                    Icon={Inventory}
                    onClick={() => setAdminOpen(false)}
                    dark={adminOpen}
                />
            )}

            {userType === CLIENT && (
                <NavItem
                    to={`/accounts/${account?.id}/admin/purchase-advice`}
                    label="Purchase advice"
                    Icon={AttachMoney}
                    onClick={() => setAdminOpen(false)}
                    dark={adminOpen}
                />
            )}

            {(userType === CLIENT || userType === CUSTOMER) && (
                <NavItem
                    to={`/accounts/${account?.id}/admin/reference-guides`}
                    label="Reference guides"
                    Icon={Topic}
                    onClick={() => setAdminOpen(false)}
                    dark={adminOpen}
                />
            )}

            <NavItem
                to={`/accounts/${account?.id}/admin/roles`}
                label="Roles"
                Icon={Badge}
                onClick={() => setAdminOpen(false)}
                dark={adminOpen}
            />

            <NavItem
                to={`/accounts/${account?.id}/admin/users`}
                label="Users"
                Icon={Person}
                onClick={() => setAdminOpen(false)}
                dark={adminOpen}
            />
        </>
    );

    const showAdminInMain = isAdmin && !adminOpen;

    return (
        <nav className={styles.nav}>
            <div
                className={clsx(styles.navGroup, {
                    [styles.disableElevation]: adminOpen
                })}
            >
                <img
                    alt="premicorr logo"
                    src={logoWhite}
                    className={styles.navLogo}
                />

                <div className={styles.navListTransitionContainer}>
                    <NavList
                        direction="right"
                        in={!showAdminInMain}
                    >
                        {mainNavItems}
                    </NavList>

                    <NavList
                        direction="left"
                        in={showAdminInMain}
                    >
                        <SubMenuHeaderButton
                            isOpen={false}
                            setIsOpen={setAdminOpen}
                            title="Admin"
                        />

                        {adminNavItems}
                    </NavList>
                </div>
            </div>

            <Slide
                in={adminOpen}
                enter
                direction="right"
            >
                <div className={clsx(styles.navGroup, styles.subNavGroup)}>
                    <SubMenuHeaderButton
                        isOpen
                        setIsOpen={setAdminOpen}
                        title="Admin"
                    />

                    {adminNavItems}
                </div>
            </Slide>
        </nav>
    );
}
