import { createDate, randomBoolean, randomNum } from '@tsp-ui/core';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { privateInvestors } from '../client/investor/clientInvestor-mocks';
import { exclusions, loanProgramsBase } from '../investor/investor-mocks';

import { ExclusionOverlay, ExclusionOverlayType } from './exclusion-overlay-api';


let overlayID = 1;

export const mocks = [
    rest.get(getMockUrl('/exclusion-overlay'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.url.searchParams.get('loanProgramId')
            ? [
                ...clientOverlays, ...investorOverlays, ...loanProgramOverlays
            ] : req.url.searchParams.get('investorId') ? [ ...clientOverlays, ...investorOverlays ] : clientOverlays)
    )),
    rest.put(getMockUrl('/exclusion-overlay/:exclusionOverlayID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.body)
    )),
    rest.post(getMockUrl('/exclusion-overlay'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json({
            ...req.body as ExclusionOverlay,
            id: String(overlayID++)
        })
    ))
];

const clientOverlays: ExclusionOverlay[] = [
    {
        id: String(overlayID++),
        type: ExclusionOverlayType.CLIENT,
        name: 'Exclude New York',
        description: 'No one likes New York so exclude it globally',
        investorIds: randomBoolean() ? [ 'ALL' ] : privateInvestors.map(({ id }) => id).slice(0, randomNum(1, privateInvestors.length - 1)),
        effectiveDate: createDate(-10).toISOString(),
        exclusions
    }
];

const investorOverlays: ExclusionOverlay[] = [
    {
        id: String(overlayID++),
        type: ExclusionOverlayType.INVESTOR,
        name: 'Credit score adjustment',
        description: 'Don\'t allow credit scores below 660',
        loanProgramIds: randomBoolean() ? [ 'ALL' ] : loanProgramsBase.map(({ id }) => id).slice(0, randomNum(1, loanProgramsBase.length - 1)),
        effectiveDate: createDate(-10).toISOString(),
        exclusions
    }
];

const loanProgramOverlays: ExclusionOverlay[] = [
    {
        id: String(overlayID++),
        type: ExclusionOverlayType.LOAN_PROGRAM,
        name: 'Credit score adjustment',
        description: 'Don\'t allow credit scores below 660',
        effectiveDate: createDate(-10).toISOString(),
        exclusions
    }
];
