import { SvgIconComponent } from '@mui/icons-material';
import { ListItem, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './NavItem.module.scss';


interface NavItemProps {
    to?: string;
    exact?: boolean;
    Icon: SvgIconComponent;
    label: string;
    selected?: boolean;
    onClick?: () => void;
    dark?: boolean;
}

export default function NavItem({
    to, exact, Icon, label, selected, onClick, dark
}: NavItemProps) {
    const { pathname } = useLocation();

    return (
        <ListItem
            button
            {...(to && {
                component: Link,
                to
            })}
            selected={selected !== undefined
                ? selected
                : exact
                    ? pathname === to
                    : pathname.includes(to || '')}
            classes={{
                selected: clsx(styles.selected, {
                    [styles.dark]: dark
                })
            }}
            className={styles.root}
            onClick={onClick}
        >
            <Icon className={styles.icon} />

            <Typography
                variant="caption"
                align="center"
                className={styles.label}
            >
                {label}
            </Typography>
        </ListItem>
    );
}
