import api, { Product } from '@api';
import { Button } from '@mui/material';
import { RoutedDialogManager } from '@tsp-ui/core/components';
import {
    getItemById, replaceItemById, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Page from '../../../components/Page';
import StatusActionsCard from '../../components/StatusActionsCard';
import { ProductManagementPageContext } from '../ProductManagementPage';
import { ProductDetailsCard } from '../components/ProductDetailsCard';
import { ProductDetailsDialog } from '../components/ProductDetailsDialog';
import { ProductHistoryDialog } from '../components/ProductHistoryDialog';

import styles from './ProductDetailPage.module.scss';


export default function ProductDetailPage() {
    const [ loading, setLoading ] = useState(true);
    const [ product, setProduct ] = useGetProduct();

    const pageMessage = usePageMessage();

    const toggleActive = async () => {
        try {
            setProduct(await api.products.updateProduct({
                ...product!,
                isActive: !product?.isActive
            }));

            pageMessage.success(`Product ${product?.isActive ? 'de' : ''}activated`);
        } catch (error) {
            const action = product.isActive ? 'deactivating' : 'activating';
            pageMessage.handleApiError(`An error occurred while ${action} the product`, error);
        }
    };

    useEffect(() => product && setLoading(false), [ product ]);

    return (
        <Page
            header="Product Details"
            loading={loading}
            headerActions={(
                <Button
                    component={Link}
                    to="history"
                >
                    View history
                </Button>
            )}
            breadcrumbs={[
                'Products',
                product?.productDescription
            ]}
        >
            {product && (
                <div className={styles.root}>
                    <div>
                        <ProductDetailsCard product={product} />
                    </div>

                    <div />

                    <div>
                        <StatusActionsCard
                            actions={(
                                <Button
                                    onClick={toggleActive}
                                    fullWidth
                                >
                                    {product.isActive ? 'Deactivate' : 'Activate'}
                                </Button>
                            )}
                            noBorder={false}
                            status={product.isActive ? 'Active' : 'Inactive'}
                            variant={product.isActive ? 'ACTIVE' : 'INACTIVE'}
                        />
                    </div>
                </div>
            )}

            <RoutedDialogManager
                routes={{
                    edit: ProductDetailsDialog,
                    history: ProductHistoryDialog
                }}
            />
        </Page>
    );
}

export function useGetProduct(): [Product, ((newProduct: Product) => void)] {
    const { productID } = useParams<AdminRouteParams<'product'>>();
    const { entities, setEntities } = useContext(ProductManagementPageContext);

    return [
        getItemById(entities, productID),
        (newProduct: Product) => setEntities(replaceItemById(entities, newProduct))
    ];
}
