import api, {
    ClientDetail, ClientStatus, User, clientStatusDisplay
} from '@api';
import { EmailOutlined, PersonOutlined } from '@mui/icons-material';
import { Button, Tooltip, Typography } from '@mui/material';
import { RoutedDialogManager, SentryRoutes } from '@tsp-ui/core/components';
import {
    getFullName, getItemById, replaceItemById, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import DetailPageViewCard from '@views/admin/components/DetailPageViewCard';
import InvestorEligibilityRoutes from '@views/admin/investor-eligibility/InvestorEligibilityRoutes';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState
} from 'react';
import { Link, Route } from 'react-router-dom';

import Page from '../../../components/Page';
import { AdminRouteParams } from '../../components/AdminPageTemplate';
import EditableSectionCard from '../../components/EditableSectionCard';
import StatusActionsCard from '../../components/StatusActionsCard';
import ContactDisplay from '../../customers/CustomerDetailPage/components/dialogs/ContactDisplay';
import StatusChecklistItem from '../../customers/components/StatusChecklistItem';
import { ClientManagementPageContext } from '../ClientManagementPage';

import styles from './ClientDetailPage.module.scss';
import AuthorizedUserDialog from './components/AuthorizedUserDialog';
import ClientDetailSummaryCard from './components/ClientDetailSummaryCard';
import EditClientPrimaryContactDialog from './components/EditClientPrimaryContactDialog';


export interface ClientDetailsContextValue {
    client?: ClientDetail;
    updateClient: (updatedClient: ClientDetail) => void;
    authorizedUsers: User[];
    updateAuthorizedUsers: Dispatch<SetStateAction<User[]>>;
}

export const ClientDetailsContext = createContext<ClientDetailsContextValue>({
    client: undefined,
    updateClient: () => {},
    authorizedUsers: [],
    updateAuthorizedUsers: () => {}
});

export default function ClientDetailPage() {
    const { clientID } = useParams<AdminRouteParams<'client'>>();

    const { entities: clients, setEntities: setClients } = useContext(ClientManagementPageContext);
    const client = getItemById(clients, clientID);
    const updateClient = useCallback(
        (updatedClient: ClientDetail) => setClients(clients => replaceItemById(clients, updatedClient)), [ setClients ]
    );

    const [ authorizedUsers, setAuthorizedUsers ] = useState<User[]>([]);

    const pageMessage = usePageMessage();

    const canApprove = false; // TODO

    const clientContextValue = useMemo(() => ({
        client,
        updateClient,
        authorizedUsers,
        updateAuthorizedUsers: setAuthorizedUsers
    }), [
        client, updateClient, authorizedUsers, setAuthorizedUsers
    ]);

    useAsyncEffect(useCallback(async () => {
        try {
            setAuthorizedUsers(await api.client.getAuthorizedPremicorrUsers(clientID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching client details', error);
        }
    }, [ clientID, pageMessage ]));

    return (
        <SentryRoutes>
            <Route
                path="*"
                element={(
                    <Page
                        header="Client details"
                        headerActions={(
                            <Button
                                component={Link}
                                to="history"
                            >
                                View history
                            </Button>
                        )}
                        breadcrumbs={[
                            'Clients',
                            client?.name
                        ]}
                    >
                        {client && (
                            <ClientDetailsContext.Provider value={clientContextValue}>
                                <div className={styles.root}>
                                    <div>
                                        <ClientDetailSummaryCard />
                                    </div>

                                    <div>
                                        <DetailPageViewCard
                                            label="Investor eligibility"
                                            to="investor-eligibility"
                                        />
                                    </div>

                                    <div>
                                        <StatusActionsCard
                                            actions={(
                                                <Tooltip title={canApprove ? '' : 'Required items must be completed prior to activation'}>
                                                    <span>
                                                        <Button // TODO loader
                                                            variant="contained"
                                                            disabled={!canApprove}
                                                            fullWidth
                                                            onClick={async () => updateClient(
                                                                await api.client.updateClient({
                                                                    ...client,
                                                                    status: ClientStatus.ACTIVE
                                                                })
                                                            )}
                                                        >
                                                            Activate
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            checklistItems={( // TODO pendingItems
                                                <StatusChecklistItem label="todo" />
                                            )}
                                            noBorder={false}
                                            status={clientStatusDisplay[client.status]}
                                            variant={client.status}
                                        />

                                        <EditableSectionCard
                                            header="Primary contact"
                                            editTo="edit-primary-contact"
                                        >
                                            <ContactDisplay
                                                className={styles.clientDisplay}
                                                contact={client.primaryContact}
                                            />
                                        </EditableSectionCard>

                                        <EditableSectionCard
                                            header="Authorized premicorr users"
                                            editTo="authorized-users"
                                        >
                                            <div className={styles.users}>
                                                {authorizedUsers.map(user => (
                                                    <AuthorizedUserDisplay
                                                        key={user.id}
                                                        user={user}
                                                    />
                                                ))}
                                            </div>
                                        </EditableSectionCard>
                                    </div>
                                </div>

                                <RoutedDialogManager routes={dialogRoutes} />
                            </ClientDetailsContext.Provider>
                        )}
                    </Page>
                )}
            />

            <Route
                path="investor-eligibility/*"
                element={<InvestorEligibilityRoutes />}
            />
        </SentryRoutes>
    );
}

const dialogRoutes = {
    'edit-primary-contact': EditClientPrimaryContactDialog,
    'authorized-users': AuthorizedUserDialog
};

function AuthorizedUserDisplay({ user }: { user: User }) {
    return (
        <div
            className={styles.user}
        >
            <Typography variant="body2">
                <PersonOutlined
                    color="primary"
                    fontSize="small"
                />

                {getFullName(user)}
            </Typography>

            <Typography variant="caption">
                <EmailOutlined
                    color="primary"
                    fontSize="small"
                />

                {user.email}
            </Typography>
        </div>
    );
}
