import {
    Address,
    AmortizationType,
    AutomatedUwSystem,
    ClientLoanProgram,
    ExclusionEqualityType,
    LoanProperty,
    LoanPurpose,
    LoanType,
    NumUnits,
    NumericFieldOperator,
    OccupancyType,
    Operator,
    PropertyType,
    SpecialtyProduct
} from '@api';
import { Override } from '@tsp-ui/core/utils';

import { apiUtils } from '../api-utils';


export function getInvestors(): Promise<Investor[]> {
    return apiUtils.get('/investor');
}

export function updateInvestor(investor: Investor): Promise<Investor> {
    return apiUtils.put(`/investor/${investor.id}`, investor);
}

export function updateStatus(investorID: string, status: InvestorStatus): Promise<Investor> {
    return apiUtils.patch(`/investor/${investorID}`, { status });
}

export function updateEligibilityStatus(
    investorID: string, managedEligibilityStatus: ManagedEligibilityStatus
): Promise<Investor> {
    return apiUtils.patch(`/investor/${investorID}`, { managedEligibilityStatus });
}

export function createInvestor(investor: Omit<Investor, 'id'>): Promise<Investor> {
    return apiUtils.post('/investor', investor);
}

export function getLoanPrograms(investorID: string): Promise<InternalLoanProgram[]> {
    return apiUtils.get(`/investor/${investorID}/loan-program`);
}

export interface CreateLoanProgramReqBody {
    loanProgram: Omit<CommonLoanProgram, 'id'>;
    eligibilityGuidelineSet: Omit<EligibilityGuidelineSet, 'id' | 'loanProgramId'>;
}

export interface CreateLoanProgramResBody {
    loanProgram: CommonLoanProgram;
    eligibilityGuidelineSet: EligibilityGuidelineSet;
}

export function createLoanProgram(createLoanProgramBody: CreateLoanProgramReqBody): Promise<CreateLoanProgramResBody> {
    return apiUtils.post(`/investor/${createLoanProgramBody.loanProgram.investorId}/loan-program`, createLoanProgramBody);
}

export function updateLoanProgram(loanProgram: InternalLoanProgram): Promise<InternalLoanProgram> {
    return apiUtils.put(`/investor/${loanProgram.investorId}/loan-program/${loanProgram.id}`, loanProgram);
}

export function updateLoanProgramStatus(
    investorID: string, loanProgramID: string, status: LoanProgramStatus
): Promise<InternalLoanProgram> {
    return apiUtils.patch(`/investor/${investorID}/loan-program/${loanProgramID}`, { status });
}

export function getEligibilityGuidelineSets(
    investorID: string, loanProgramID: string
): Promise<EligibilityGuidelineSetSummary[]> {
    return apiUtils.get(`/investor/${investorID}/loan-program/${loanProgramID}/guideline-set`);
}

export function getEligibilityGuidelineSetDetail(
    investorID: string, loanProgramID: string, guidelineSetID: string
): Promise<EligibilityGuidelineSet> {
    return apiUtils.get(`/investor/${investorID}/loan-program/${loanProgramID}/guideline-set/${guidelineSetID}`);
}

export function deleteEligibilityGuidelineSet(
    investorID: string, loanProgramID: string, guidelineSetID: string
): Promise<void> {
    return apiUtils.delete(`/investor/${investorID}/loan-program/${loanProgramID}/guideline-set/${guidelineSetID}`);
}

export function createEligibilityGuidelineSet(guidelineSet: Omit<EligibilityGuidelineSet, 'id'>, investorID: string): Promise<EligibilityGuidelineSetSummary> {
    return apiUtils.post(`/investor/${investorID}/loan-program/${guidelineSet.loanProgramId}/guideline-set`, guidelineSet);
}

export function updateEligibilityGuidelineSet(
    guidelineSet: EligibilityGuidelineSet, investorID: string
): Promise<EligibilityGuidelineSetSummary> {
    return apiUtils.put(`/investor/${investorID}/loan-program/${guidelineSet.loanProgramId}/guideline-set/${guidelineSet.id}`, guidelineSet);
}

export function expireEligibilityGuidelineSet(
    investorID: string, loanProgramID: string, guidelineSetID: string
): Promise<EligibilityGuidelineSetSummary> {
    return apiUtils.patch(`/investor/${investorID}/loan-program/${loanProgramID}/guideline-set/${guidelineSetID}`, { expirationDate: new Date() });
}

export function getExclusions(investorID: string): Promise<EligibilityExclusions> {
    return apiUtils.get(`/investor/${investorID}/eligibility-exclusions`);
}

export function createExclusions(investorID: string, exclusions: Override<EligibilityExclusions, { exclusions?: Omit<EligibilityExclusion, 'id'>[] }>): Promise<EligibilityExclusions> {
    return apiUtils.post(`/investor/${investorID}/eligibility-exclusions`, exclusions);
}

export function updateExclusions(
    investorID: string, exclusions: EligibilityExclusions
): Promise<EligibilityExclusions> {
    return apiUtils.put(`/investor/${investorID}/eligibility-exclusions`, exclusions);
}

export enum InvestorStatus {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum ManagedEligibilityStatus {
    MANAGED = 'MANAGED',
    PENDING = 'PENDING',
    UNMANAGED = 'UNMANAGED'
}

export interface Investor {
    id: string;
    code?: string;
    name: string;
    legalName: string;
    isAgency: boolean;
    status: InvestorStatus;
    managedEligibilityStatus: ManagedEligibilityStatus;
    addresses: Address[];
    activeClientIDs: string[];
}

export enum LoanProgramStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export interface InternalLoanProgram {
    id: string;
    name: string;
    description: string;
    investorId: string;
    status: LoanProgramStatus;
}

// represents Loan Program that may not have client specific properties
export type CommonLoanProgram = Partial<ClientLoanProgram> & InternalLoanProgram;

export interface EligibilityGuidelineSetSummary {
    id: string;
    loanProgramId: string;
    effectiveDate: string;
    expirationDate?: string;
    isOverlay?: boolean;
}

export interface EligibilityGuidelineSet extends EligibilityGuidelineSetSummary {
    highLevelGuidelines: HighLevelGuideline[];
    guidelines: EligibilityGuideline[];
    exclusions: EligibilityExclusions;
    comments?: string;
}

export interface EligibilityMatrixOverlay extends EligibilityGuidelineSet {
    isOverlay: true;
}

export interface HighLevelGuideline {
    id: string;
    loanProperty: LoanProperty;
    operator: Operator;
    value: string | number | boolean
        | LoanType
        | OccupancyType
        | LoanPurpose
        | AmortizationType
        | PropertyType
        | AutomatedUwSystem
        | SpecialtyProduct;
}

export interface EligibilityGuideline {
    id: string;
    guidelineSetId: string;
    loanType?: LoanType;
    occupancy?: OccupancyType;
    purpose?: LoanPurpose;
    amortType?: AmortizationType;
    propertyType?: PropertyType;
    aus?: AutomatedUwSystem;
    specialtyProduct?: SpecialtyProduct;
    highBalance?: boolean;
    units?: NumUnits;
    minFICO?: number;
    maxFICO?: number;
    minLTV?: number;
    maxLTV?: number;
    minCLTV?: number;
    maxCLTV?: number;
    minDTI?: number;
    maxDTI?: number;
    minTerm?: number;
    maxTerm?: number;
    minReservesMonths?: number;
}

export interface EligibilityExclusion {
    id: string;
    loanProperty: LoanProperty | '';
    value: string[] | NumericExclusionValue | boolean | '';
    equalityType?: ExclusionEqualityType | '';
}

export interface NumericExclusionValue {
    operator: NumericFieldOperator;
    min?: number;
    max?: number;
}

export interface EligibilityExclusions {
    operator: 'and' | 'or';
    exclusions?: EligibilityExclusion[];
    groups?: EligibilityExclusionGroup[];
}

export interface EligibilityExclusionGroup {
    exclusions: EligibilityExclusion[];
}
