import {
    CommonLoanProgram, InternalLoanProgram, Investor, InvestorStatus
} from '@api';

import { apiUtils } from '../../api-utils';


export function getInvestors(clientID: string): Promise<ClientInvestor[]> {
    return apiUtils.get(`/client/${clientID}/investor`);
}

export function getAgencyInvestors(clientID: string): Promise<ClientInvestor[]> {
    return apiUtils.get(`/client/${clientID}/investor?showAgencyOnly=true`);
}

export function updateInvestor(investor: ClientInvestor, clientID: string): Promise<ClientInvestor> {
    return apiUtils.put(`/client/${clientID}/investor/${investor.id}`, investor);
}

export function activateManagedInvestor(payload: ActivateManagedInvestorPayload): Promise<ClientInvestor> {
    return apiUtils.post('/investor/managed', payload);
}

export function updateStatus(
    investorID: string, status: InvestorStatus, clientID: string
): Promise<ClientInvestor> {
    return apiUtils.patch(`/client/${clientID}/investor/${investorID}`, { status });
}

export function addInvestor(investor: Omit<ClientInvestor, 'id'>, clientID: string): Promise<ClientInvestor> {
    return apiUtils.post(`/client/${clientID}/investor`, investor);
}

export function updateStatusPremManaged(
    investorID: number, params: { status: InvestorStatus, sellerServicerNumber?: number }, clientID: string
): Promise<ClientInvestor> {
    return apiUtils.patch(`/client/${clientID}/investor/managed/${investorID}`, params);
}

export function getLoanPrograms(investorID: string): Promise<ClientLoanProgram[]> {
    return apiUtils.get(`/investor/${investorID}/loan-program`);
}

export type ClientInvestor = Omit<Investor, 'activeClientIds'> & {
    sellerServicerNumber?: string;
    eligibilityBasedOnInvestorId?: string;
    managedInvestorIsActive?: boolean;
}

interface ActivateManagedInvestorPayload {
    investorId: string;
    sellerServicerNumber: string;
}

export interface ClientLoanProgram extends InternalLoanProgram {
    baseLoanProgramId: string | undefined;
    hasMatrixOverlay: boolean;
}

export function isClientLoanProgram(commonLoanProgram: CommonLoanProgram): commonLoanProgram is ClientLoanProgram {
    return commonLoanProgram.hasOwnProperty('baseLoanProgramId') && commonLoanProgram.hasOwnProperty('hasMatrixOverlay');
}
