import { ArrowBack, Menu } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import styles from './SubMenuHeaderButton.module.scss';


interface SubMenuHeaderButtonProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    title: string;
}

export default function SubMenuHeaderButton({ isOpen, setIsOpen, title }: SubMenuHeaderButtonProps) {
    return (
        <div className={styles.root}>
            <Tooltip title={isOpen ? 'Collapse menu' : 'Expand menu'}>
                <IconButton
                    color="inherit"
                    className={styles.closeSubNav}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? (
                        <ArrowBack />
                    ) : (
                        <Menu />
                    )}
                </IconButton>
            </Tooltip>

            <Typography
                color="inherit"
                variant="body2"
                className={styles.subMenuHeader}
            >
                {title}
            </Typography>
        </div>
    );
}
