import {
    Address,
    AmortizationType,
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    Borrower,
    CommitmentType,
    DocumentationType,
    LoanEventType,
    LoanPurpose,
    LoanStatus,
    LoanType,
    NumUnits,
    OccupancyType,
    PendingUpload,
    PropertyType,
    RegistrationType,
    SpecialtyProgram,
    User
} from '..';
import { apiUtils } from '../api-utils';

/**
 * loanID - premicorr loan ID, unique guid we use to track a loan, created when loan is created
 *
 * loanNumber - represents either the non-unique customer loan number (before the loan is registered),
 * or the unique client loan number (after the loan is registered)
 *
 * customerLoanNumber - only used after the loan is registered when both the client loan number &
 * customer loan number need to be displayed
 */

export function uploadLoans(formData: FormData): Promise<PendingUpload[]> {
    return apiUtils.post('/loan/file-upload', formData, { useAutoContentType: true });
}

// TODO post-demo remove this
export function updateLoanStatus(loanId: string, loanStatus: LoanStatus): Promise<void> {
    return apiUtils.patch(`/loan/${loanId}/status`, {
        id: loanId,
        loanStatus,
        pipelineGroupId: 1
    });
}

export function getLoans(): Promise<Loan[]> {
    return apiUtils.get('/loan');
}

export function getLoanAlerts(): Promise<LoanAlert[]> {
    return apiUtils.get('/loan/alert');
}

export function getRecentLoans(): Promise<LoanActivity[]> {
    return apiUtils.get('/loan/recent');
}

export function getPipelineGroups(): Promise<PipelineGroup[]> {
    return apiUtils.get('/loan/group');
}

export function registerLoan(body: LoanRegistrationParams): Promise<LoanRegistrationResponse> {
    return apiUtils.post('/loan/register', body);
}

export function getLoanEvents(loanID: string): Promise<LoanEvent[]> {
    return apiUtils.get(`/loan/${loanID}/events`);
}

export function getLoanDetail(loanID: string): Promise<LoanDetail> {
    return apiUtils.get(`/loan/${loanID}`);
}

export interface LoanRegistrationParams {
    loanId: string;
    commitmentType: CommitmentType;
    lockPeriod: number;
    registrationType: RegistrationType;
    productId: string;
}

export interface LoanRegistrationResponse {
    clientLoanNumber: string;
    premicorrLoanID: string;
}

export interface Loan {
    id: string; // (premicorr loanID)
    loanNumber: string;
    customerLoanNumber: string;
    borrowerName: string;
    loanAmount: number;
    loanStatus: LoanStatus;
    expirationDate: string;
    interestRate: number;
    pipelineGroupID: string;
}

export interface LoanAlert {
    id: string;
    loanID: string;
    loanNumber: string;
    alertType: string;
    triggeredAt: string;
}

export interface LoanActivity { // marks when the loan was last viewed / updated
    id: string;
    loanID: string;
    loanNumber: string;
    lastActiveAt: string;
}

export interface PipelineGroup {
    id: string; // (pipelineGroupID)
    title: string;
}

export interface LoanEvent {
    id: string;
    loanId: string;
    eventType: LoanEventType;
    triggeredBy: User;
    triggeredAt: string;
}

export interface LoanDetail {
    id: string;
    loanNumber: string; // client loan number
    assignee?: User;
    loanStatus: LoanStatus;
    loanType: LoanType;
    loanAmount: number;
    interestRate: number;

    amortizationType: AmortizationType;
    armMargin?: number;
    armInitialCap?: number;
    armSubsequentCap?: number;
    armLifeCap?: number;

    escrowsFlag: boolean;
    interestOnlyFlag: boolean;
    loanTerm: number;
    lockPeriod: number;
    borrowers: Borrower[];
    propertyType: PropertyType;
    units: NumUnits;
    occupancy: OccupancyType;
    purpose: LoanPurpose;
    address: Address;
    appraisedValue: number;
    salePrice: number;

    customerId: string;
    customerName: string;
    customerLoanNumber: string;
    productCode?: string;
    specialtyProgram?: SpecialtyProgram;
    documentationType?: DocumentationType;
    subordinatedBalance?: number;
    cashOutAmount?: number;
    limitedLiabilityCorp?: string;
    commitmentType?: CommitmentType;
    commitmentIdentifier?: string;
    comments?: string;
    mortgageInsFlag?: boolean;
    mortgageInsCompany?: string;
    mortgageInsCoverage?: number;
    underwriteFlag?: boolean;
    automatedUwSystem?: AutomatedUwSystem;
    automatedUwRecommendation?: AutomatedUwRecommendation;
}
