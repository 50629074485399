export enum ClientStatus {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    INACTIVE = 'INACTIVE',
    SUSPENDED = 'SUSPENDED'
}

export const clientStatusDisplay = {
    [ClientStatus.ACTIVE]: 'Active',
    [ClientStatus.PENDING]: 'Pending',
    [ClientStatus.INACTIVE]: 'Inactive',
    [ClientStatus.SUSPENDED]: 'Suspended'
};
