import {
    LoanPricingResult, Notification, NotificationType, PendingUpload
} from '@api';
import * as Sentry from '@sentry/react';

import { RetryWebSocket } from './RetryWebSocket';
import { WebSocketEvent } from './WebSocketEvent';
import { WebSocketEventType } from './WebSocketEventType';


export interface WebSocketEventData<TData = unknown> {
    type: WebSocketEventType;
    data: TData;
}

type WebSocketSubscriptionCallback<TData> = (data: TData) => void;

/**
 * Websocket for Premicorr
 */
export class PremicorrWebSocket {
    private readonly _eventTarget: EventTarget = new EventTarget();
    private _retrySocket: RetryWebSocket | undefined;
    private _mockNotificationId = 10;

    constructor() {
        this._initializeSocket = this._initializeSocket.bind(this);
        this._handleMessage = this._handleMessage.bind(this);
    }

    /**
     * Recreates the underlying RetryWebSocket with the given url. If the socket already exists, it will be closed
     * before creating the new instance.
     *
     * @param url - The url of the websocket to connect to
     */
    set url(url: string) {
        try {
            if (this._retrySocket) {
                this._retrySocket.close();
            }

            this._retrySocket = new RetryWebSocket(url, this._initializeSocket);
        } catch (error) {
            Sentry.captureException(error, {
                extra: { url }
            });
        }
    }

    /**
     * Gets the url of the underlying retry socket. Since set only accepts a string, this get also needs to
     * return a string, hence the returning of an empty string if rety socket isn't set yet
     */
    get url() {
        return this._retrySocket?.socket.url || '';
    }

    /**
     * Passed to the underlying RetryWebSocket to add the message handler
     *
     * @param socket - The socket from the underlying RetryWebSocket
     */
    private _initializeSocket(socket: WebSocket) {
        socket.onmessage = this._handleMessage;
    }

    /**
     * When a message is received from the underlying RetryWebSocket, dispatch an event on this instance with the event
     * type set to the WebSocketEventType from the message data.
     *
     * @param message - The MessageEvent from the socket
     */
    private _handleMessage(message: MessageEvent) {
        const { type, data }: WebSocketEventData = JSON.parse(message.data);

        this._eventTarget.dispatchEvent(new WebSocketEvent(type, data));
    }

    /**
     * Wraps the given WebSocketSubscriptionCallback to call it with the data from the WebSocketEvent
     *
     * @param callback - The WebSocketSubscriptionCallback to wrap
     */
    private _wrapCallback<TData>(callback: WebSocketSubscriptionCallback<TData>): EventListener {
        return (event) => {
            if (event instanceof WebSocketEvent) { // Should always be true, but necessary for TS
                callback(event.data);
            }
        };
    }

    /**
     * Closes the underlying RetryWebSocket
     */
    public close() {
        this._retrySocket?.close();
    }

    /**
     * Overload for user notification events
     */
    public subscribe(
        type: WebSocketEventType.USER_NOTIFICATION,
        callback: WebSocketSubscriptionCallback<Notification>
    ): () => void;

    /**
     * Overload for user notification events
     */
    public subscribe(
        type: WebSocketEventType.UPLOAD_COMPLETE,
        callback: WebSocketSubscriptionCallback<PendingUpload>
    ): () => void;

    /**
     * Overload for pricing result events
     */
    public subscribe(
        type: WebSocketEventType.PRICING_COMPLETE,
        callback: WebSocketSubscriptionCallback<LoanPricingResult>
    ): () => void;

    /**
     * Subscribes to the given WebSocketEventType. Overloads for specific WebSocketEventTypes should be
     * defined above, hence the `never` argument passed to the actual implementation.
     *
     * @param   type     - The WebSocketEventType to subscribe to
     * @param   callback - The event listener callback
     * @returns an unsubscribe function
     */
    public subscribe(
        type: WebSocketEventType,
        callback: WebSocketSubscriptionCallback<never>
    ) {
        const wrappedCallback = this._wrapCallback(callback);

        this._eventTarget.addEventListener(type, wrappedCallback);

        return () => {
            this._eventTarget.removeEventListener(type, wrappedCallback);
        };
    }

    public simulateNotification() {
        this._eventTarget.dispatchEvent(new WebSocketEvent(WebSocketEventType.USER_NOTIFICATION, {
            id: `${this._mockNotificationId++}`,
            type: NotificationType.LOAN_ROLE_ASSIGNMENT,
            description: 'Some description',
            loanNumber: '123456789',
            createdDate: new Date().toISOString(),
            isRead: false
        }));
    }

    public simulatePricingComplete() {
        this._eventTarget.dispatchEvent(new WebSocketEvent(WebSocketEventType.PRICING_COMPLETE, undefined));
    }

    public simulateUploadComplete(file: PendingUpload) {
        this._eventTarget.dispatchEvent(new WebSocketEvent(WebSocketEventType.UPLOAD_COMPLETE, file));
    }
}
