import { rest } from 'msw';

import { CustomerPendingSteps, CustomerStatus } from '..';
import { getMockUrl } from '../../mocks/getMockUrl';

import { branches, mocks as branchesMocks } from './branches/branches-mocks';
import { mocks as checklistMocks } from './checklist/checklist-mocks';
import { mocks as compensationMocks } from './compensation/compensation-mocks';
import { contacts, mocks as contactsMocks } from './contacts/contacts-mocks';
import { CustomerDetails, CustomerPendingStep, CustomerSummary } from './customer-api';
import { productConfiguration, mocks as productsMocks } from './eligibleProducts/eligibleProducts-mocks';
import { mocks as eventHistoryMocks } from './eventHistory/eventHistory-mocks';
import { mocks as licensesMocks } from './licenses/licenses-mocks';
import { mocks as wireInfoMocks } from './wireInfo/wireInfo-mocks';


let newCustomerID = 100;

export const mocks = [
    rest.get(getMockUrl('/customer'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(customerSummaryResults)
    ))),
    rest.post(getMockUrl('/customer'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as CustomerDetails,
            id: `${newCustomerID++}`
        })
    ))),
    rest.get(getMockUrl('/customer/:customerID'), (req, res, ctx) => {
        somePendingSteps.pop();

        return (res(
            ctx.delay(1000),
            ctx.status(200),
            ctx.json(customer)
        ));
    }),
    rest.put(getMockUrl('/customer/:customerID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    ...branchesMocks,
    ...checklistMocks,
    ...compensationMocks,
    ...contactsMocks,
    ...eventHistoryMocks,
    ...licensesMocks,
    ...productsMocks,
    ...wireInfoMocks
];

const somePendingSteps: CustomerPendingStep[] = [
    {
        id: '5',
        name: CustomerPendingSteps.COMP_FEES,
        isRequired: true
    },
    {
        id: '6',
        name: CustomerPendingSteps.LICENSES,
        isRequired: true
    },
    {
        id: '7',
        name: CustomerPendingSteps.DUE_DILIGENCE,
        isRequired: true
    },
    {
        id: '8',
        name: CustomerPendingSteps.WIRE_INSTRUCTIONS,
        isRequired: false
    },
    {
        id: '8',
        name: CustomerPendingSteps.ELIGIBLE_PRODUCTS,
        isRequired: false
    }
];

const customerSummaryResults: CustomerSummary[] = [
    {
        id: '1',
        name: 'AmCap Mortgate, Ltd.',
        status: CustomerStatus.PENDING,

        dbaNames: [ 'AmCap Mortgate, Ltd.' ],
        nmlsId: '129122',

        branches,
        pendingSteps: somePendingSteps
    },
    {
        id: '2',
        name: 'American Bank',
        status: CustomerStatus.PENDING,

        dbaNames: [ 'American Bank' ],
        nmlsId: '417017',

        branches,
        pendingSteps: []
    },
    {
        id: '3',
        name: 'Bank of Grand Lake',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Bank of Grand Lake' ],
        nmlsId: '654321'
    },
    {
        id: '4',
        name: 'Ark Mortgage, Inc.',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Ark Mortgage, Inc.' ],
        nmlsId: '654321',

        branches
    },
    {
        id: '5',
        name: 'Bank of Clarke County',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Bank of Clarke County' ],
        nmlsId: '654321',

        branches
    },
    {
        id: '6',
        name: 'Bay Equity LLC',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Bay Equity LLC' ],
        nmlsId: '654321',

        branches
    },
    {
        id: '7',
        name: 'A I E Financial, Inc.',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'A I E Financial, Inc.' ],
        nmlsId: '654321',

        branches
    },
    {
        id: '8',
        name: 'AmRes Corporation',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'AmRes Bank' ],
        nmlsId: '654321',

        branches
    },
    {
        id: '9',
        name: 'Barton Creek Lending Group LLC',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Barton Creek Lending Group LLC' ],
        nmlsId: '654321',

        branches
    },
    {
        id: '10',
        name: 'Bay-Valley Mortgage Group',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Bay-Valley Mortgage Group' ],
        nmlsId: '654321',

        branches
    },
    {
        id: '11',
        name: 'Inactive Bank',
        status: CustomerStatus.INACTIVE,

        dbaNames: [ 'Inactive Bank' ],
        nmlsId: '123456',

        branches
    }
];

export const customer: CustomerDetails = {
    id: '1',
    name: 'American Bank',
    nmlsId: '123456',
    taxID: '1234567890',
    address: branches[0].address,
    dbaNames: [ 'AmCap Mortgage, Ltd.', 'American Bank LTD' ],
    productConfiguration,
    primaryContact: contacts[0],
    counterpartyAnalystId: '1',
    primaryAccountExecId: '2',
    secondaryAccountExecId: '3',
    accountManagerId: '4',
    status: CustomerStatus.PENDING,
    pendingSteps: somePendingSteps,
    branches
};
