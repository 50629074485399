import { HeaderContext } from '@views/AuthenticatedRouteSwitch';
import Page from '@views/components/Page';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';

import styles from './CreateLoanProgramPage.module.scss';
import { LoanProgramForm } from './LoanProgramDetailPage/components/LoanProgramForm';


export default function CreateLoanProgramPage() {
    const [ activeStep, setActiveStep ] = useState(0);
    const { setToolbarClassName } = useContext(HeaderContext);

    useEffect(() => {
        if (activeStep === 1) {
            setToolbarClassName(styles.unsetMaxWidth);

            return () => setToolbarClassName(undefined);
        } else {
            setToolbarClassName(undefined);
        }
    }, [ activeStep, setToolbarClassName ]);

    return (
        <Page
            header="Add Loan Program"
            variant="centered"
            className={clsx(styles.main, {
                [styles.unsetMaxWidth]: activeStep === 1
            })}
        >
            <LoanProgramForm
                activeStep={activeStep}
                setActiveStep={setActiveStep}
            />
        </Page>
    );
}
