import api, {
    NotificationConfig,
    Permission,
    PermissionCategory,
    Role,
    permissionCategoryDisplay,
    permissionLevelDisplay,
    userTypeDisplay
} from '@api';
import { Typography } from '@mui/material';
import { LabelGroup, LabeledValue, RoutedDialogManager } from '@tsp-ui/core/components';
import {
    replaceItemById, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState
} from 'react';

import Page from '../../components/Page';
import { AdminRouteParams } from '../components/AdminPageTemplate';
import EditableSectionCard from '../components/EditableSectionCard';

import styles from './RoleDetailPage.module.scss';
import { RoleManagementPageContext } from './RoleManagementPage';
import EditPermissionsDialog from './components/EditPermissionsDialog';
import EditRoleDetailsDialog from './components/EditRoleDetailsDialog';
import NotificationDialog from './notifications/NotificationDialog';
import NotificationsSection from './notifications/NotificationsSection';


interface RoleDetailContextValues {
    role?: Role;
    setRole: (role: Role) => void;
    permissions: Permission[];
    updatePermissions: Dispatch<SetStateAction<Permission[]>>;
    notifications: NotificationConfig[];
    updateNotifications: Dispatch<SetStateAction<NotificationConfig[]>>;
}

export const RoleDetailPageContext = createContext<RoleDetailContextValues>({
    role: undefined,
    setRole: () => {},
    permissions: [],
    updatePermissions: () => {},
    notifications: [],
    updateNotifications: () => {}
});

export default function RoleDetailPage() {
    const [ permissions, setPermissions ] = useState<Permission[]>([]);
    const [ notifications, setNotifications ] = useState<NotificationConfig[]>([]);
    const [ loading, setLoading ] = useState(true);

    const { roleID } = useParams<AdminRouteParams<'role'>>();
    const { entities: roles, setEntities: setRoles } = useContext(RoleManagementPageContext);

    const role = roles.find(({ id }) => roleID === `${id}`);
    const setRole = useCallback(
        (role: Role) => setRoles(replaceItemById(roles, role)),
        [ roles, setRoles ]
    );

    const roleDetailContextValues = useMemo(() => ({
        role,
        setRole,
        permissions,
        updatePermissions: setPermissions,
        notifications,
        updateNotifications: setNotifications
    }), [
        permissions, setPermissions, role, setRole, notifications, setNotifications
    ]);

    const { userType, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        try {
            setNotifications(
                await api.roles.notifications.getNotificationConfigs(userType, parseInt(roleID), customerId)
            );
            setPermissions(await api.roles.getPermissions(userType, parseInt(roleID), customerId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching role details', error);
        }

        setLoading(false);
    }, [
        customerId, pageMessage, roleID, userType
    ]));

    return (
        <Page
            header="Role Details"
            loading={loading}
            breadcrumbs={[
                'Roles',
                role?.name
            ]}
        >
            <RoleDetailPageContext.Provider value={roleDetailContextValues}>
                <div className={styles.root}>
                    <div>
                        <EditableSectionCard
                            header={role?.name}
                            editTo="edit"
                        >
                            <LabelGroup>
                                <LabeledValue
                                    label="Description:"
                                    value={role?.description}
                                />

                                <LabeledValue
                                    label="Role type:"
                                    value={role?.userType ? userTypeDisplay[role.userType] : ''}
                                />
                            </LabelGroup>
                        </EditableSectionCard>

                        <EditableSectionCard
                            header="Permissions"
                            editTo="permissions"
                        >
                            <div className={styles.viewPermissions}>
                                {Object.keys(PermissionCategory).map(category => (
                                    <CategoryPermissionsView
                                        key={category}
                                        category={category as PermissionCategory}
                                        permissions={permissions}
                                    />
                                ))}
                            </div>
                        </EditableSectionCard>
                    </div>

                    <div />

                    <div>
                        <NotificationsSection notifications={notifications} />
                    </div>
                </div>

                <RoutedDialogManager routes={dialogRoutes} />
            </RoleDetailPageContext.Provider>
        </Page>
    );
}

const dialogRoutes = {
    permissions: EditPermissionsDialog,
    edit: EditRoleDetailsDialog,
    'notifications/new': NotificationDialog,
    'notifications/:notificationID/edit': NotificationDialog
};

interface CategoryPermissionsViewProps {
    category: PermissionCategory;
    permissions: Permission[];
}

function CategoryPermissionsView({ category, permissions }: CategoryPermissionsViewProps) {
    const categoryPermissions = permissions.filter(perm => perm.category === category);

    return categoryPermissions.length ? (
        <div key={category}>
            <Typography className={styles.sectionViewHeader}>
                {permissionCategoryDisplay[category]}
            </Typography>

            <LabelGroup className={styles.rolesView}>
                {categoryPermissions.map(({
                    description, id, level
                }) => (
                    <LabeledValue
                        key={id}
                        variants={{ value: 'body2' }}
                        value={permissionLevelDisplay[level]}
                        label={`${description}:`}
                    />
                ))}
            </LabelGroup>
        </div>
    ) : null;
}
