import api, {
    AmortizationType, Product, amortizationTypeDisplay, occupancyTypeDisplay, productTypeDisplay,
    specialtyProductDisplay
} from '@api';
import {
    FormControl, FormControlLabel, FormLabel, MenuItem, Switch as MuiSwitch
} from '@mui/material';
import {
    CurrencyField, PercentField, Radio, RadioGroup, Switch, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { AdminAddEditFormProps } from '@views/admin/components/AdminAddPageTemplate';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { InvestorsContext } from '../ProductManagementPage';

import styles from './ProductDetailsForm.module.scss';


export function ProductDetailsForm({ entityToEdit, onSubmit, setLoading } : AdminAddEditFormProps<Product>) {
    const [ allowTermRange, setAllowTermRange ] = useState(!!entityToEdit?.minTerm || !!entityToEdit?.maxTerm);
    const { investors } = useContext(InvestorsContext);

    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const formMethods = useForm<Product>({ defaultValues: entityToEdit });
    const showARMFields = formMethods.watch('amortizationType') === AmortizationType.ADJUSTABLE;

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setLoading(true);

        formData = showARMFields ? formData : removeARMFields(formData);
        formData = allowTermRange ? formData : removeTermRange(formData);

        try {
            onSubmit(entityToEdit
                ? await api.products.updateProduct(formData)
                : await api.products.createProduct(formData));

            navigate('..');

            pageMessage.success('Product details saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the product details', error);
        }

        setLoading(false);
    });

    return (
        <form
            id={ProductDetailsForm.formID}
            onSubmit={handleSubmit}
            className={styles.root}
            noValidate
        >
            <FormProvider {...formMethods}>
                <TextField<Product>
                    name="productDescription"
                    label="Description"
                    className={styles.fullWidth}
                    required
                />

                <TextField<Product>
                    name="productCode"
                    label="Code"
                    required
                />

                <TextField<Product>
                    name="productType"
                    label="Product type"
                    required
                    select
                >
                    {renderEnumOptions(productTypeDisplay)}
                </TextField>

                <TextField<Product>
                    name="term"
                    label="Term (months)"
                    type="number"
                    required
                />

                <FormControlLabel
                    control={(
                        <MuiSwitch
                            checked={allowTermRange}
                            onChange={() => setAllowTermRange(!allowTermRange)}
                        />
                    )}
                    label="Allow term range"
                />

                {allowTermRange && (
                    <>
                        <TextField<Product>
                            name="minTerm"
                            label="Min term"
                            type="number"
                        />

                        <TextField<Product>
                            name="maxTerm"
                            label="Max term"
                            type="number"
                        />
                    </>
                )}

                <TextField<Product>
                    name="specialtyProduct"
                    label="Specialty product"
                    className={styles.fullWidth}
                    select
                >
                    {renderEnumOptions(specialtyProductDisplay)}
                </TextField>

                <CurrencyField<Product>
                    name="minLoanAmount"
                    label="Min loan amount"
                />

                <CurrencyField<Product>
                    name="maxLoanAmount"
                    label="Max loan amount"
                />

                <TextField<Product>
                    name="occupancyTypes"
                    label="Restrict by occupancy type"
                    className={styles.fullWidth}
                    select
                    SelectProps={{ multiple: true }}
                >
                    {renderEnumOptions(occupancyTypeDisplay)}
                </TextField>

                <TextField<Product>
                    name="investorIDs"
                    label="Investors"
                    className={styles.fullWidth}
                    select
                    SelectProps={{ multiple: true }}
                >
                    {investors.map(({ id, name }) => (
                        <MenuItem
                            value={id}
                            key={id}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </TextField>

                <div className={styles.sectionStart}>
                    <FormControl component="fieldset">
                        <FormLabel
                            component="legend"
                            required
                        >
                            Amortization type
                        </FormLabel>

                        <RadioGroup<Product>
                            name="amortizationType"
                            defaultValue={AmortizationType.FIXED}
                        >
                            <Radio
                                value={AmortizationType.FIXED}
                                label={amortizationTypeDisplay[AmortizationType.FIXED]}
                            />

                            <Radio
                                value={AmortizationType.ADJUSTABLE}
                                label={amortizationTypeDisplay[AmortizationType.ADJUSTABLE]}
                            />
                        </RadioGroup>
                    </FormControl>

                    {showARMFields && (
                        <FormControl
                            className={styles.sectionStart}
                            component="fieldset"
                        >
                            <FormLabel
                                component="legend"
                                required
                            >
                                Amortization details
                            </FormLabel>

                            <div className={styles.amortizationDetailsFields}>
                                <TextField<Product>
                                    name="armInitialPeriod"
                                    label="ARM initial period"
                                    type="number"
                                    required
                                />

                                <TextField<Product>
                                    name="armSubPeriod"
                                    label="ARM sub period"
                                    type="number"
                                    required
                                />

                                <PercentField<Product>
                                    name="armInitialRateCap"
                                    label="ARM initial rate cap"
                                    required
                                />

                                <PercentField<Product>
                                    name="armSubRateCap"
                                    label="ARM sub rate cap"
                                    required
                                />

                                <PercentField<Product>
                                    name="armLifeRateCap"
                                    label="ARM life rate cap"
                                    required
                                />

                                <TextField<Product>
                                    name="armLookBackDays"
                                    label="ARM lookback days"
                                    type="number"
                                    required
                                />
                            </div>
                        </FormControl>
                    )}
                </div>

                <div className={styles.sectionStart}>
                    <Switch<Product>
                        name="nonAgency"
                        label="Non agency"
                    />

                    <Switch<Product>
                        name="highBalance"
                        label="High balance"
                    />

                    <Switch<Product>
                        name="jumbo"
                        label="Jumbo"
                    />

                    <Switch<Product>
                        name="servicingReleased"
                        label="Servicing released"
                    />

                    <Switch<Product>
                        name="streamline"
                        label="Streamline"
                    />
                </div>
            </FormProvider>
        </form>
    );
}

ProductDetailsForm.formID = 'product-details-form';

function removeARMFields(product: Product) {
    return {
        ...product,
        armInitialPeriod: undefined,
        armSubPeriod: undefined,
        armInitialRateCap: undefined,
        armSubRateCap: undefined,
        armLifeRateCap: undefined,
        armLookBackDays: undefined
    };
}

function removeTermRange(product: Product) {
    return {
        ...product,
        minTerm: undefined,
        maxTerm: undefined
    };
}
