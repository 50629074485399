import {
    AmortizationType,
    OccupancyType,
    PaginatedGetParams,
    ProductEventType,
    ProductType,
    SpecialtyProduct,
    User
} from '@api';
import { PaginatedResponse } from '@tsp-ui/core';

import { apiUtils } from '../api-utils';


export function getAllProducts(): Promise<Product[]> {
    return apiUtils.get('/client/product');
}

export function updateProduct(product: Product): Promise<Product> {
    return apiUtils.put(`/client/product/${product.id}`, product);
}

export function createProduct(product: Partial<Product>): Promise<Product> {
    return apiUtils.post('/client/product', product);
}

export function getProductHistory(
    productID: string, params: ProductHistoryParams
): Promise<PaginatedResponse<ProductHistoryEvent>> {
    return apiUtils.get(`/client/product/${productID}/history`, params);
}

export interface ProductHistoryEvent {
    id: string;
    eventType: ProductEventType;
    date: string;
    executedBy: User;
    before: Product;
    after: Product;
}

export interface ProductHistoryParams extends PaginatedGetParams {
    date?: string | null;
    eventType?: ProductEventType;
}

export interface Product {
    id: string;
    productCode: string;
    productDescription: string;
    isActive: boolean;
    productType: ProductType;
    amortizationType: AmortizationType;
    term: number;

    minTerm?: number;
    maxTerm?: number;
    minLoanAmount?: number;
    maxLoanAmount?: number;

    highBalance: boolean;
    jumbo: boolean;
    specialtyProduct?: SpecialtyProduct;
    nonAgency: boolean;
    servicingReleased: boolean;
    streamline: boolean;

    armInitialPeriod?: number;
    armSubPeriod?: number;
    armInitialRateCap?: number;
    armSubRateCap?: number;
    armLifeRateCap?: number;
    armLookBackDays?: number;

    investorIDs: string[];
    occupancyTypes?: OccupancyType[];
}
