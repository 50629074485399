import {
    AmortizationType,
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    CommitmentType,
    DocumentationType,
    LoanPurpose,
    LoanType,
    LockPeriod,
    OccupancyType,
    PropertyType,
    SpecialtyProgram,
    State
} from '..';
import { apiUtils } from '../api-utils';


export function getPricingResults(): Promise<LoanPricingResult[]> {
    return apiUtils.get('/pricing');
}

// TODO this will need to be in the loans api, might update response type
export function getFloatedLoans(): Promise<LoanPricingResult[]> {
    return apiUtils.get('/pricing/floated');
}

export function getPricingResultDetail(loanID: string): Promise<LoanPricingResultDetail> {
    return apiUtils.get(`/pricing/${loanID}`);
}

export function priceLoan(body: ManualLoanEntry): Promise<LoanPricingResult> {
    return apiUtils.post('/pricing/price', body);
}

export function repriceLoan(loanID: string): Promise<LoanPricingResult> {
    return apiUtils.post('/pricing/reprice', { loanID });
}

export interface LoanPricingResult {
    loanId: string;
    borrowerName: string;
    expirationDate: string; // TODO post-demo move this to the product result
    interestRate: number;
    loanAmount: number;
    loanNumber: string;
    pricedDate: string;
    rateSheetId: string;
}

export interface LoanPricingResultDetail {
    defaultLockPeriod: LockPeriod;
    products: PricingResultProduct[];
    ineligibleProducts?: PricingResultIneligibleProduct[];
}

export interface PricingResultProduct {
    productId: string;
    basePrice: number;
    commitmentType: CommitmentType;
    description: string;
    docUrl: string;
    finalPrice: number;
    investor: string;
    lockPeriod: LockPeriod;
    margin: number;
    llpas: number;
    adjustments: PricingResultAdjustment[];
}

export interface PricingResultIneligibleProduct {
    productId: string;
    description: string;
    ineligibleReason: string;
}

export interface PricingResultAdjustment {
    id: string;
    amount: number;
    capTotal: number;
    description: string;
    gos: boolean;
    isCap: boolean;
    srp: boolean;
}

export interface PendingUpload {
    fileId: string;
    fileName: string;
    status: 'complete' | 'pending' | 'error';
}

export interface ManualLoanEntry {
    // borrower step
    borrowers: Borrower[];
    // property step
    occupancyType: OccupancyType;
    loanPurpose: LoanPurpose;
    propertyType: PropertyType;
    appraisedValue: number;
    salesPrice: number;
    propertyUnits: number;
    propertyStreetAddress1: string;
    propertyStreetAddress2: string;
    propertyCity: string;
    propertyState: State;
    propertyZipCode: string;
    propertyCounty: string;
    // loan step
    customerLoanNumber: string;
    universalLoanIdentifier: string;
    loanAmount: number;
    baseLoanAmount: number;
    loanType: LoanType;
    loanTerm: number;
    interestRate: number;
    lockPeriod: number;
    loanFICO: number;
    dti: number;
    amorType: AmortizationType;
    armMargin?: number;
    armInitialCap?: number;
    armSubsequentCaps?: number;
    armLifeCap?: number;
    escrowsFlag: boolean;
    interestOnlyFlag: boolean;
    // additional details step
    productCode?: string;
    specialtyProgram?: SpecialtyProgram;
    documentationType?: DocumentationType;
    subordinatedBalance?: number;
    cashOutAmount?: number;
    limitedLiabilityCorp?: string;
    commitmentType?: CommitmentType;
    commitmentIdentifier?: string;
    comments?: string;
    mortgageInsFlag?: boolean;
    mortgageInsCompany?: string;
    mortgageInsCoverage?: number;
    underwriteFlag?: boolean;
    automatedUwSystem?: AutomatedUwSystem;
    automatedUwRecommendation?: AutomatedUwRecommendation;
}

export interface Borrower { // TODO post-demo this should have an id property
    firstName?: string;
    middleName?: string;
    lastName?: string;
    fico?: number;
    ssn?: string;
    email?: string;
    primaryWageEarner?: boolean;
    firstTimeHomeBuyer?: boolean;
}
