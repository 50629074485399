import { createApiUtils, getCognitoToken } from '@tsp-ui/core/utils';


let clientToken: string;

export const apiUtils = {
    ...createApiUtils({
        getAuthToken: getCognitoToken,
        getCustomHeaders: () => ({ 'client-token': clientToken }),
        getRequestUrl: (url, options, config) => config?.apiUrl + url
    }),

    setClientToken(newClientToken: string) {
        clientToken = newClientToken;
    },

    getClientToken() {
        return clientToken;
    }
};
