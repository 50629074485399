import { Account, UserType } from '@api';
import { rest } from 'msw';

import redUrl from '../../images/logo-client.png';
import greenUrl from '../../images/logo-customer.png';
import { getMockUrl } from '../../mocks/getMockUrl';


export const mocks = [
    rest.get(getMockUrl('/auth/client'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(accounts)
    ))),
    rest.post(getMockUrl('/auth/client'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({ accessToken: 'mockToken' })
    )))
];

export const accounts: Account[] = [
    {
        id: '1',
        name: 'Client premicorr',
        logoUrl: redUrl,
        userType: UserType.CLIENT
    },
    {
        id: '2',
        name: 'Customer premicorr',
        logoUrl: greenUrl,
        userType: UserType.CUSTOMER,
        customerId: 1
    }
];
