import api, { LoanPricingResult, PendingUpload } from '@api';
import { Button, Link as MuiLink, Typography } from '@mui/material';
import { ExpandableHeader, FileInput, RoutedDialogManager } from '@tsp-ui/core/components';
import { capitalize, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import LoanUploadResultsDialog from '@views/product-pricing/components/LoanUploadResultsDialog';
import {
    Dispatch, SetStateAction, useCallback, useState
} from 'react';
import { Link } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import Page from '../components/Page';

import styles from './ProductAndPricing.module.scss';
import LoanPricingResultCard from './components/LoanPricingResultCard';
import PendingUploadsCard from './components/PendingUploadsCard';


export const productAndPricingAcceptedFileTypes = [
    'xls', 'xlsx', 'csv', 'txt', 'fnm'
];

export default function ProductAndPricingPage() {
    const pageMessage = usePageMessage();

    const [ pendingUploads, setPendingUploads ] = useState<PendingUpload[]>();
    const [ loanPricingResults, setLoanPricingResults ] = useState<LoanPricingResult[]>();

    const [ floatedLoans, setFloatedLoans ] = useState<LoanPricingResult[]>();
    const [ loading, setLoading ] = useState(true);

    useAsyncEffect(useCallback(async () => {
        try {
            // TODO post-demo
            // const [ pricingResults, floatedLoans ] = await Promise.all(
            //     [ api.pricing.getPricingResults(), api.pricing.getFloatedLoans() ]
            // );
            setLoanPricingResults(await api.pricing.getPricingResults());
            // setLoanPricingResults(pricingResults);
            // setFloatedLoans(floatedLoans);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the pending pricing results', error);
        }

        setLoading(false);
    }, [ pageMessage ]));

    async function handleUpload(files: File[]) {
        const formData = new FormData();
        files.forEach(file => formData.append('files', file));

        try {
            setPendingUploads(await api.loans.uploadLoans(formData));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading loan files', error);
        }
    }

    return  (
        <Page
            header="Product & Pricing"
            loading={loading}
            headerActions={(
                <Button>
                    View rate sheet
                </Button>
            )}
        >
            <div className={styles.root}>
                <div className={styles.sections}>
                    <ProductAndPricingSection
                        type="floated"
                        loanPricingResults={floatedLoans}
                        setLoanPricingResults={setLoanPricingResults}
                        setFloatedLoans={setFloatedLoans}
                    />

                    <ProductAndPricingSection
                        type="pending"
                        loanPricingResults={loanPricingResults}
                        setLoanPricingResults={setLoanPricingResults}
                        setFloatedLoans={setFloatedLoans}
                    />
                </div>

                <div>
                    <Typography
                        fontWeight={500}
                        className={styles.sectionHeader}
                    >
                        Upload new loans
                    </Typography>

                    <div className={styles.fileInputContainer}>
                        <FileInput
                            compact
                            title="file"
                            acceptedFileTypes={productAndPricingAcceptedFileTypes}
                            onAddFiles={handleUpload}
                        />

                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                        >
                            Don't have a file?
                            {' '}

                            <MuiLink
                                component={Link}
                                to="new/manual"
                            >
                                Add a loan manually
                            </MuiLink>

                            {' '}
                            instead
                        </Typography>
                    </div>

                    <Typography
                        fontWeight={500}
                        className={styles.sectionHeader}
                    >
                        Pending uploads
                    </Typography>

                    <PendingUploadsCard
                        pendingUploads={pendingUploads}
                        setLoanPricingResults={setLoanPricingResults}
                    />
                </div>
            </div>

            <RoutedDialogManager routes={routes} />
        </Page>
    );
}

const routes = {
    'uploads/*': LoanUploadResultsDialog
};

interface ProductAndPricingSectionProps {
    type: 'floated' | 'pending';
    loanPricingResults: LoanPricingResult[] | undefined;
    setLoanPricingResults: Dispatch<SetStateAction<LoanPricingResult[] | undefined>>;
    setFloatedLoans: Dispatch<SetStateAction<LoanPricingResult[] | undefined>>;
}

function ProductAndPricingSection({
    type, loanPricingResults, setLoanPricingResults, setFloatedLoans
}: ProductAndPricingSectionProps) {
    return (
        <div>
            <ExpandableHeader
                defaultExpand={type === 'pending'}
                disableExpand={!loanPricingResults?.length}
                title={`${capitalize(type)} loans`}
                secondaryText={loanPricingResults && (
                    loanPricingResults.length
                        ? `${loanPricingResults.length} loan${loanPricingResults.length > 1 ? 's' : ''}`
                        : `No loans are currently ${type}`
                )}
                expandedContent={(
                    <TransitionGroup className={styles.pricingResults}>
                        {loanPricingResults?.map((loanPricingResult, index) => (
                            <LoanPricingResultCard
                                key={loanPricingResult.loanId}
                                loanPricingResult={loanPricingResult}
                                updateLoanPricingResults={setLoanPricingResults}
                                updateFloatedLoans={setFloatedLoans}
                                index={index}
                            />
                        )) || (
                            <Typography>
                                There are no loan pricing results to display at this time.
                            </Typography>
                        )}
                    </TransitionGroup>
                )}
            />
        </div>
    );
}
