import api from '@api';
import { Toolbar } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useAsyncEffect } from '@tsp-ui/core';
import { Loader, SentryRoutes } from '@tsp-ui/core/components';
import { useTryGetCurrentAccount, useTryGetCurrentUser } from '@utils/hooks';
import { withAuthentication } from '@utils/withAuthentication';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, createContext, useCallback, useEffect, useMemo, useState
} from 'react';
import { Route } from 'react-router-dom';

import { apiUtils } from '../api/api-utils';

import styles from './AuthenticatedRouteSwitch.module.scss';
import { default as NotFoundPageBase } from './NotFoundPage';
import { default as AccountDashboardPageBase } from './accounts/AccountDashboardPage';
import { default as SwitchAccountsPageBase } from './accounts/SwitchAccountsPage';
import { default as AdminRouteSwitchBase } from './admin/AdminRouteSwitch';
import MainNav from './components/MainNav/MainNav';
import AccountButton from './components/MainNav/components/AccountButton';
import NotificationsButton from './components/MainNav/components/NotificationsButton/NotificationsButton';
import { default as LoanDetailPageBase } from './loans/LoanDetailPage';
import { default as LoansPageBase } from './loans/LoansPage';
import { default as ManualLoanEntryPageBase } from './product-pricing/ManualLoanEntryPage';
import { default as ProductAndPricingPageBase } from './product-pricing/ProductAndPricingPage';


const AdminRouteSwitch = withAuthentication(AdminRouteSwitchBase);
const AccountDashboardPage = withAuthentication(AccountDashboardPageBase);
const LoansPage = withAuthentication(LoansPageBase);
const LoanDetailPage = withAuthentication(LoanDetailPageBase);
const ManualLoanEntryPage = withAuthentication(ManualLoanEntryPageBase);
const ProductAndPricingPage = withAuthentication(ProductAndPricingPageBase);
const SwitchAccountsPage = withAuthentication(SwitchAccountsPageBase);
const NotFoundPage = withAuthentication(NotFoundPageBase);

export const HeaderContext = createContext<{
    toolbarClassName: string | undefined;
    setToolbarClassName: Dispatch<SetStateAction<string | undefined>>;
}>({
    toolbarClassName: undefined,
    setToolbarClassName: () => {}
});

export default function AuthenticatedRouteSwitch() {
    const user = useTryGetCurrentUser();
    const account = useTryGetCurrentAccount();
    const { id: accountID } = account || {};

    const [ accessToken, setAccessToken ] = useState<string | undefined>(apiUtils.getClientToken());

    useAsyncEffect(useCallback(async () => {
        if (accountID) {
            const { accessToken } = await api.auth.becomeClient(accountID);
            // TODO post-demo handle errors
            setAccessToken(accessToken);
            apiUtils.setClientToken(accessToken);
        } else {
            apiUtils.setClientToken('');
            setAccessToken(undefined);
        }
    }, [ accountID ]));

    useEffect(() => {
        if (account) {
            Sentry.setContext('account', account);
        }
    }, [ account ]);

    const [ toolbarClassName, setToolbarClassName ] = useState<string>();

    const headerContextValue = useMemo(() => ({
        toolbarClassName,
        setToolbarClassName
    }), [ toolbarClassName ]);

    const tokenLoading = user && !accessToken && !!accountID;

    const [ showLoader, setShowLoader ] = useState(false);
    useEffect(() => {
        setTimeout(() => setShowLoader(true), 300);
    }, []);

    return (
        <div className={styles.root}>
            <HeaderContext.Provider value={headerContextValue}>
                {user && (
                    <>
                        <MainNav account={account} />

                        <header className={styles.headerContainer}>
                            <Toolbar className={clsx(styles.toolbar, toolbarClassName)}>
                                {account && (
                                    <>
                                        <img
                                            alt="account logo"
                                            src={account.logoUrl}
                                            className={styles.logo}
                                        />

                                        {!tokenLoading && <NotificationsButton />}
                                    </>
                                )}

                                <AccountButton account={account} />
                            </Toolbar>
                        </header>
                    </>
                )}

                {tokenLoading && showLoader && <Loader loading />}

                {!tokenLoading && (
                    <SentryRoutes>
                        <Route
                            path="/accounts"
                            element={<SwitchAccountsPage />}
                        />

                        <Route
                            path="/accounts/:accountID"
                            element={<AccountDashboardPage />}
                        />

                        <Route
                            path="/accounts/:accountID/admin/*"
                            element={<AdminRouteSwitch />}
                        />

                        <Route
                            path="/accounts/:accountID/loans/*"
                            element={<LoansRoutes />}
                        />

                        <Route
                            path="/accounts/:accountID/product-pricing/*"
                            element={<ProductAndPricingPage />}
                        />

                        <Route
                            path="/accounts/:accountID/product-pricing/new/manual"
                            element={<ManualLoanEntryPage />}
                        />

                        <Route
                            path="*"
                            element={<NotFoundPage />}
                        />
                    </SentryRoutes>
                )}
            </HeaderContext.Provider>
        </div>
    );
}

function LoansRoutes() {
    return (
        <SentryRoutes>
            <Route
                path="/"
                element={<LoansPage />}
            />

            <Route
                path=":loanID"
                element={<LoanDetailPage />}
            />

            <Route
                path=":loanID/:underwritingTab"
                element={<LoanDetailPage />}
            />
        </SentryRoutes>
    );
}
