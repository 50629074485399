import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { SAMPLE_PDF_URL } from '../customer/checklist/checklist-mocks';

import { ReferenceGuideSection, ReferenceGuideUpload } from './referenceGuides-api';


let referenceGuideSectionID = 4;
let referenceGuideID = 100;

export const mocks = [
    rest.get(getMockUrl('/document/client/referenceGuide'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(referenceGuideSections)
    ))),
    rest.post(getMockUrl('/document/client/referenceGuide'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as ReferenceGuideSection,
            id: `${referenceGuideSectionID++}`,
            files: []
        })
    ))),
    rest.post(getMockUrl('/document/client/referenceGuide/:referenceGuideSectionID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            id: `${referenceGuideSectionID++}`,
            title: (req.body as ReferenceGuideUpload).title,
            description: (req.body as ReferenceGuideUpload).description,
            sectionID: (req.body as ReferenceGuideUpload).sectionId,
            createdAt: new Date(),
            url: SAMPLE_PDF_URL
        })
    ))),
    rest.put(getMockUrl('/document/client/referenceGuide/:referenceGuideSectionID/:referenceGuideID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.delete(getMockUrl('/document/client/referenceGuide/:referenceGuideSectionID/:referenceGuideID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.delete(getMockUrl('/document/client/referenceGuide/:referenceGuideSectionID'), (req, res, ctx) => (res(
        ctx.status(200)
    )))
];

const referenceGuideSections: ReferenceGuideSection[] = [
    {
        id: '2',
        title: 'Some section',
        files: [
            {
                id: `${referenceGuideID++}`,
                sectionId: '2',
                title: 'Some reference guide title',
                description: 'This is a description for a reference guide file. This file is used to describe a, b, and c.',
                createdAt: '2021-04-05T16:18:06.526Z',
                url: SAMPLE_PDF_URL
            },
            {
                id: `${referenceGuideID++}`,
                sectionId: '2',
                title: 'Another reference guide title',
                url: SAMPLE_PDF_URL,
                description: 'This is a description for a reference guide file. This file is used to describe d, e, and f.',
                createdAt: '2021-05-06T16:18:06.526Z'
            }
        ]
    },
    {
        id: '3',
        title: 'Another section',
        files: [
            {
                id: `${referenceGuideID++}`,
                sectionId: '3',
                title: 'Additional reference guide title',
                url: SAMPLE_PDF_URL,
                description: 'This is a description for a reference guide file. This file is used to describe g, h, and i.',
                createdAt: '2021-06-07T16:18:06.526Z'
            },
            {
                id: `${referenceGuideID++}`,
                sectionId: '3',
                title: 'Supplimental reference guide title',
                url: SAMPLE_PDF_URL,
                description: 'This is a description for a reference guide file. This file is used to describe x, y, and z.',
                createdAt: '2021-07-08T16:18:06.526Z'
            }
        ]
    }
];

