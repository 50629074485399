import api, {
    NotificationConfig,
    NotificationMethod,
    NotificationParam,
    NotificationType,
    conditionEventDisplay,
    conditionExceptionStatusDisplay,
    loanRoleAssignmentDisplay,
    loanStatusDelegatedDisplay,
    loanStatusNonDelegatedDisplay,
    notificationFrequencyDisplay
} from '@api';
import {
    Edit, Email, Notifications, RemoveCircleOutline
} from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { IconTypography, ProgressIndicator } from '@tsp-ui/core/components';
import {
    useConfirm, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { Fragment, useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AdminRouteParams } from '../../components/AdminPageTemplate';
import { RoleDetailPageContext } from '../RoleDetailPage';

import styles from './NotificationCard.module.scss';


const {
    LOAN_STATUS_DELEGATED,
    LOAN_STATUS_NON_DELEGATED,
    CONDITION_EVENT,
    LOAN_ROLE_ASSIGNMENT,
    CONDITION_EXCEPTION_STATUS
} = NotificationType;

interface NotificationCardProps {
    notification: NotificationConfig;
}

export default function NotificationCard({
    notification: {
        frequency, id, methods, parameters, type
    }
}: NotificationCardProps) {
    const confirm = useConfirm();
    const pageMessage = usePageMessage();
    const { updateNotifications } = useContext(RoleDetailPageContext);
    const location = useLocation();

    const [ isDeleting, setIsDeleting ] = useState(false);

    const { roleID } = useParams<AdminRouteParams<'role'>>();

    const { userType, customerId } = useGetCurrentAccount();

    const emailEnabled = methods.includes(NotificationMethod.EMAIL);
    const inAppEnabled = methods.includes(NotificationMethod.IN_APP);

    const deleteNotification = async () => {
        setIsDeleting(true);

        if (await confirm('Are you sure you want to delete this notification?')) {
            try {
                await api.roles.notifications.deleteNotification(userType, parseInt(roleID), id, customerId);

                updateNotifications(notifications => notifications.filter(notification => notification.id !== id));

                pageMessage.success('Notification deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the notification', error);
            }
        }

        setIsDeleting(false);
    };

    return (
        <Paper
            variant="outlined"
            className={styles.notification}
        >
            <div>
                <Typography>
                    {(type === LOAN_STATUS_DELEGATED || type === LOAN_STATUS_NON_DELEGATED)
                        && <>When <b>loan status</b> changes to</>}

                    {type === CONDITION_EVENT && <>When <b>condition</b> is marked as</>}

                    {type === LOAN_ROLE_ASSIGNMENT && <>When <b>role</b> is assigned to</>}

                    {type === CONDITION_EXCEPTION_STATUS && <>When <b>condition exception</b> is</>}

                    {displayParameters(parameters, {
                        ...loanStatusDelegatedDisplay,
                        ...loanStatusNonDelegatedDisplay,
                        ...conditionEventDisplay,
                        ...loanRoleAssignmentDisplay,
                        ...conditionExceptionStatusDisplay
                    })}
                </Typography>

                <IconTypography
                    fontWeight={400}
                    variant="body2"
                    color="textSecondary"
                    iconPosition="after"
                    icon={(
                        <span className={styles.notificationIcons}>
                            <Tooltip
                                title={`Email notifications ${emailEnabled ? 'en' : 'dis'}abled`}
                                enterDelay={0}
                            >
                                <Email
                                    color={emailEnabled ? 'primary' : 'disabled'}
                                    fontSize="small"
                                />
                            </Tooltip>

                            <Tooltip
                                title={`In-app notifications ${inAppEnabled ? 'en' : 'dis'}abled`}
                                enterDelay={0}
                            >
                                <Notifications
                                    color={inAppEnabled ? 'primary' : 'disabled'}
                                    fontSize="small"
                                />
                            </Tooltip>
                        </span>
                    )}
                >
                    {notificationFrequencyDisplay[frequency]}
                </IconTypography>
            </div>

            <div className={styles.buttons}>
                <Tooltip
                    title="Edit notification"
                    enterDelay={0}
                >
                    <IconButton
                        component={Link}
                        to={`${location.pathname}/notifications/${id}/edit`}
                    >
                        <Edit color="secondary" />
                    </IconButton>
                </Tooltip>

                {isDeleting ? <ProgressIndicator className={styles.progress} /> : (
                    <Tooltip
                        title="Delete notification"
                        enterDelay={0}
                    >
                        <IconButton onClick={deleteNotification}>
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </Paper>
    );
}

function displayParameters(parameters: NotificationParam[], displayObj: {
    [key: string]: string;
}) {
    return parameters.map((parameter, index) => (
        <Fragment key={parameter}>
            <b> {displayObj[parameter]}</b>

            {parameters.length > 1 && index < parameters.length - 1 && (
                `,${index === parameters.length - 2 ? ' or' : ''}`
            )}
        </Fragment>
    ));
}
