import { getAccount } from '@redux/auth';
import { useSelector } from '@redux/store';
import { useRouteMatch } from '@tsp-ui/core/utils';


export function useGetCurrentAccount() {
    const account = useTryGetCurrentAccount();

    if (!account) {
        throw new Error('No current account found. If this is expected, use useTryGetCurrentAccount instead.');
    }

    return account;
}

export function useTryGetCurrentAccount() {
    const accountID = useRouteMatch('accounts/:accountID/*')?.params.accountID;

    return useSelector((state) => getAccount(state, accountID));
}
